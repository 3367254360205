import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteTask, getAllTasks, saveNewTask, updateTask } from '../../actions/masterAction';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import {
    Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, SubHeader, FilterContainer, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput, StyledTextarea, Icon, Checkbox, ButtonContainer, SearchButton, NoDataFound, PageCountContainer
} from '../';
import { DropDownStyle, pageSizeOptions, states } from '../../helpers';
import Modal from 'react-bootstrap/Modal';
import { checkDataIsValid } from '../../CheckData';
import warningSvg from '../../assets/images/warning.svg';

const frequency = [
    { value: '0', label: 'Weekly' },
    { value: '1', label: 'Monthly' },
    { value: '2', label: 'Quarterly' },
    { value: '3', label: 'Yearly' },
    { value: '99', label: 'Once' }
];

class Tasks extends Component {
    state = {
        tasks: [],
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        sortBy: '_id',
        order: 'desc',
        taskForEdit: {},
        edit: false,
        addNew: false,
        isUpdateWarningModalOpen: false,
        isDeleteWarningModalOpen: false,
        name: ''
    };

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(getAllTasks({ ...this.state }));
    };

    componentWillReceiveProps = (updatedProps) => {
        const { pageSize } = this.state;
        const { allTasks, taskCount } = updatedProps;
        const pageCount = Math.ceil(taskCount / pageSize);
        this.setState({
            tasks: allTasks,
            totalCount: taskCount,
            pageCount: pageCount
        });
    };

    handlePageClick = async (event) => {
        const { dispatch } = this.props;
        const { pageSize } = this.state;
        this.setState({
            pageNumber: event.selected + 1,
            addNew: false,
            edit: false,
            taskForEdit: {
                id: '',
                name: '',
                frequency: '',
                interval: '0',
                forms: [],
                instructions: '',
                locationOptions: [],
                locationTypes: [],
                states: [],
                roles: [],
                taskFor: ['location'],
                taskType: 'global'
            }
        });
        await dispatch(getAllTasks({ ...this.state, pageNumber: event.selected + 1, pageSize: pageSize }));
    };

    handleFilterInputChange = async (value) => {
        this.setState({
            pageNumber: 1,
            pageSize: 10,
            name: value
        });
    };

    getFilteredData = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        await dispatch(getAllTasks({ ...this.state }));
    };

    resetFilter = async () => {
        const { dispatch } = this.props;
        this.setState({
            addNew: false,
            edit: false,
            pageNumber: 0,
            pageSize: 10,
            taskForEdit: {},
            sortBy: '_id',
            order: 'desc',
            name: ''
        });
        await dispatch(getAllTasks({
            pageNumber: 0, pageSize: 10, sortBy: '_id',
            order: 'desc',
        }));
    };

    handlePageSizeInputChanges = async (value) => {
        const { dispatch } = this.props;
        this.setState({
            pageSize: value,
            pageNumber: 1,
        });
        await dispatch(getAllTasks({ ...this.state, pageNumber: 1, pageSize: value }));
    };

    handleSortByAndOrderChange = async (sortBy, order) => {
        const { dispatch } = this.props;
        this.setState({
            sortBy,
            order,
            pageNumber: 1,
            pageSize: 10
        });
        await dispatch(getAllTasks({ ...this.state, pageNumber: 1, pageSize: 10, sortBy, order }));
    };

    handelEditRow = (task) => {
        this.setState({
            edit: true,
            addNew: false,
            taskForEdit: task
        });
    };

    deleteTask = async () => {
        const { taskForEdit } = this.state;
        const { dispatch } = this.props;
        const data = await dispatch(deleteTask(taskForEdit));
        if (data) {
            await dispatch(getAllTasks(this.state));
            this.setState({
                edit: false,
                addNew: false,
                isUpdateWarningModalOpen: false,
                isDeleteWarningModalOpen: false
            });
        };
    };

    handelModelClose = () => {
        const { taskForEdit } = this.state;
        this.setState({
            isUpdateWarningModalOpen: false,
            taskForEdit: { ...taskForEdit, changeForAll: false },
            isDeleteWarningModalOpen: false
        });
    };

    handelCheckboxChange = ({ target: { checked, name } }) => {
        const { taskForEdit } = this.state;
        this.setState({
            taskForEdit: { ...taskForEdit, changeForAll: checked }
        });
    };

    handelDropDownChange = (name, value) => {
        const { taskForEdit } = this.state;
        if (name === 'frequency') {
            this.setState({
                taskForEdit: { ...taskForEdit, frequency: value.value }
            })
        };
        if (name === 'states') {
            const states = value.map(state => state.value);
            this.setState({
                taskForEdit: { ...taskForEdit, states }
            });
        };
    };

    handelInputChange = (value, name) => {
        const { taskForEdit } = this.state;
        this.setState({
            taskForEdit: { ...taskForEdit, [name]: value.trimStart() }
        });
    };

    updateTasks = async () => {
        const { dispatch, allTasks } = this.props;
        const { taskForEdit } = this.state;
        const currentStates = allTasks.find((task) => task._id === taskForEdit._id).states || [];
        const newAddedStates = taskForEdit.states.filter((state) => !currentStates.includes(state)) || [];
        const removedStates = currentStates.filter((state) => !taskForEdit.states.includes(state)) || [];

        const isValid = checkDataIsValid({ 'Task Name': taskForEdit.name });
        if (isValid) {
            const data = await dispatch(updateTask(taskForEdit, { currentStates, newAddedStates, removedStates }));
            if (data) {
                await dispatch(getAllTasks(this.state));
                this.setState({
                    edit: false,
                    addNew: false,
                    isUpdateWarningModalOpen: false
                });
            };
        };
    };

    updateOrSaveTask = async () => {
        const { edit, addNew } = this.state;
        if (edit) {
            await this.updateTasks();
            return;
        };
        if (addNew) {
            await this.saveNewTask();
            return;
        }
    };

    saveNewTask = async () => {
        const { dispatch } = this.props;
        const { taskForEdit } = this.state;
        const isValid = checkDataIsValid({ 'Task Name': taskForEdit.name });
        if (isValid) {
            const data = await dispatch(saveNewTask(taskForEdit));
            if (data) {
                await dispatch(getAllTasks(this.state));
                this.setState({
                    edit: false,
                    addNew: false,
                    isUpdateWarningModalOpen: false
                });
            };
        };
    };

    addNewTask = async () => {
        await this.resetFilter();
        this.setState({
            addNew: true,
            edit: false,
            taskForEdit: {
                id: '',
                name: '',
                frequency: '',
                interval: '0',
                forms: [],
                instructions: '',
                locationOptions: [],
                locationTypes: [],
                states: [],
                roles: [],
                taskFor: ['location'],
                taskType: 'global'
            }
        });
    };
    render() {
        const { tasks, pageSize, pageCount, pageNumber, sortBy, order, edit, isDeleteWarningModalOpen, isUpdateWarningModalOpen, name, totalCount, taskForEdit, addNew } = this.state;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;
        return (
            <PageContainer width='100%'>
                <SubHeader>
                    <FilterContainer>
                        <InputContainer>
                            <StyledLabel>Tasks</StyledLabel>
                            <StyledInput
                                type="text"
                                value={name}
                                onChange={(e) => this.handleFilterInputChange(e.target.value)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <StyledLabel>&ensp;</StyledLabel>
                            <ButtonContainer>
                                <SearchButton disabled={!name.length} type='submit' onClick={this.getFilteredData} width='20%' >
                                    <Icon className='fa fa-search'></Icon>
                                </SearchButton>

                                <SearchButton reset={true} onClick={this.resetFilter} width='20%' type='button'>
                                    <Icon className='fa fa-undo'></Icon>
                                </SearchButton>
                                <SearchButton reset={false} onClick={this.addNewTask} width='20%' type='button'>
                                    <Icon className='fa fa-plus'></Icon>
                                </SearchButton>
                            </ButtonContainer>
                        </InputContainer>
                    </FilterContainer>
                    <PageCountContainer>
                        <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                    </PageCountContainer>
                    <PageSizeContainer>
                        <StyledLabel>&ensp;</StyledLabel>
                        <div>
                            <Select
                                styles={DropDownStyle}
                                defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                options={pageSizeOptions}
                                onChange={(e) => this.handlePageSizeInputChanges(e.value)}
                            />
                        </div>
                    </PageSizeContainer>
                </SubHeader>
                <TableContainer maxHeight='73vh'>
                    <Table className="table  table-bordered ">
                        <TableHead>
                            <TableRow>
                                <TableHeaderElement className='w-25' scope="col">Tasks
                                    {sortBy !== 'name' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'name' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('name', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'name' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-25' scope="col">Instructions
                                    {sortBy !== 'instructions' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('instructions', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'instructions' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('instructions', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'instructions' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('instructions', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15' scope="col">Frequency
                                    {sortBy !== 'frequency' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('frequency', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'frequency' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('frequency', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'frequency' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('frequency', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15' scope="col">State
                                    {sortBy !== 'states' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('states', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'desc' && sortBy === 'states' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('states', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                    {order === 'asc' && sortBy === 'states' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('states', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15 text-center' scope="col">Action </TableHeaderElement>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {addNew &&
                                <TableRow key='taskForEdit'>
                                    <TableDataElement className='w-25'>
                                        <StyledInput name='name' onChange={(e) => this.handelInputChange(e.target.value, 'name')} value={taskForEdit.name ? taskForEdit.name : ''} />
                                    </TableDataElement>
                                    <TableDataElement className='w-25'>
                                        <StyledTextarea name='instructions' onChange={(e) => this.handelInputChange(e.target.value, 'instructions')} value={taskForEdit.instructions ? taskForEdit.instructions : ''} />
                                    </TableDataElement>
                                    <TableDataElement className='w-15'>
                                        <Select
                                            styles={DropDownStyle}
                                            defaultValue={frequency.find((fr) => fr.value === taskForEdit.frequency)}
                                            name='frequency'
                                            onChange={(e) => this.handelDropDownChange('frequency', e)}
                                            options={frequency} />
                                    </TableDataElement>
                                    <TableDataElement className='w-15'>
                                        <Select
                                            styles={DropDownStyle}
                                            defaultValue={states.filter((st) => taskForEdit.states.includes(st.value))}
                                            onChange={(e) => this.handelDropDownChange('states', e)}
                                            isMulti={true}
                                            options={states}
                                        />
                                    </TableDataElement>
                                    <TableDataElement className='w-15 text-center'>
                                        <Icon
                                            onClick={() => this.setState({
                                                isUpdateWarningModalOpen: true
                                            })}
                                            className='fa fa-save text-success' />
                                        <Icon className='fa fa-undo text-danger' onClick={(e) => this.setState({ edit: false, addNew: false })} />
                                    </TableDataElement>
                                </TableRow>
                            }
                            {tasks && tasks.map((task) =>
                                <TableRow key={task._id}>
                                    {edit && taskForEdit._id === task._id ?
                                        <TableDataElement className='w-25'>
                                            <StyledInput name='name' onChange={(e) => this.handelInputChange(e.target.value, 'name')} value={taskForEdit.name ? taskForEdit.name : ''} />
                                        </TableDataElement> :
                                        <TableDataElement className='w-25'>{
                                            task.name ? task.name : '--'}
                                        </TableDataElement>
                                    }
                                    {edit && taskForEdit._id === task._id ?
                                        <TableDataElement className='w-25'>
                                            <StyledTextarea name='instructions' onChange={(e) => this.handelInputChange(e.target.value, 'instructions')} value={taskForEdit.instructions ? taskForEdit.instructions : ''} />
                                        </TableDataElement> :
                                        <TableDataElement className='w-25'>{
                                            task.instructions ? task.instructions : '--'}
                                        </TableDataElement>
                                    }   {
                                        edit && taskForEdit._id === task._id ?
                                            <TableDataElement className='w-15'>
                                                <Select
                                                    styles={DropDownStyle}
                                                    defaultValue={frequency.find((fr) => fr.value === task.frequency)}
                                                    name='frequency'
                                                    onChange={(e) => this.handelDropDownChange('frequency', e)}
                                                    options={frequency} />
                                            </TableDataElement>
                                            :
                                            <TableDataElement className='w-15'>
                                                {task.frequency ? frequency.find((fr) => fr.value === task.frequency).label : '--'}
                                            </TableDataElement>}
                                    {
                                        edit && taskForEdit._id === task._id ?
                                            <TableDataElement className='w-15'>
                                                <Select
                                                    styles={DropDownStyle}
                                                    defaultValue={states.filter((st) => taskForEdit.states.includes(st.value))}
                                                    onChange={(e) => this.handelDropDownChange('states', e)}
                                                    isMulti={true}
                                                    options={states}
                                                />
                                            </TableDataElement> : <TableDataElement className='w-15'>
                                                {task.states && task.states.length ?
                                                    states.filter((st) => task.states.includes(st.value)).map((st) => st.label).toString() : '--'
                                                }
                                            </TableDataElement>
                                    }
                                    <TableDataElement className='w-15 text-center'>{edit && taskForEdit._id === task._id ?
                                        <>
                                            <Icon
                                                onClick={() => this.setState({
                                                    isUpdateWarningModalOpen: true
                                                })}
                                                className='fa fa-save text-success' />
                                            <Icon className='fa fa-undo text-danger' onClick={(e) => this.setState({ edit: false, addNew: false })} />
                                        </> :
                                        <>
                                            <Icon onClick={() => this.handelEditRow(task)} className='fa fa-pencil text-success' />
                                            {task.newlyAddedTask &&
                                                <Icon onClick={() => { this.setState({ isDeleteWarningModalOpen: true, taskForEdit: task }) }} className='fa fa-trash text-danger' />}
                                        </>
                                    }
                                    </TableDataElement>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {
                        (!tasks || !tasks.length) &&
                        <NoDataFound>No Data Found</NoDataFound>
                    }
                </TableContainer>
                <PaginationContainer>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item hide"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link nextAndPrev"
                        nextClassName="page-item"
                        nextLinkClassName="page-link nextAndPrev"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                    />
                </PaginationContainer>
                {/* Update tasks modal.  */}
                <Modal
                    show={isUpdateWarningModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Do you want to do this action?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Apply for all existing and future practices <Checkbox name='changeForAll' onChange={this.handelCheckboxChange} checked={taskForEdit.changeForAll} />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-danger' onClick={this.handelModelClose}>
                            Discard and close
                        </button>
                        <button onClick={this.updateOrSaveTask} className='btn btn-success'>Submit and close</button>
                    </Modal.Footer>
                </Modal>
                {/* Delete task modal. */}
                <Modal
                    show={isDeleteWarningModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <div className='mx-4 mt-4'>
                        <div className='d-flex justify-content-center'>
                            <img height='100' width='100' alt="Warning" src={warningSvg} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <h2> Are you sure?</h2>
                        </div>
                    </div>
                    <Modal.Body className='pt-0'>
                        <p className='text-center m-0'>Do you really want to delete the task? </p>
                        <p className='text-center m-0'>This process cannot be undone.</p>
                        {/* <p>Delete for all existing and future practices <Checkbox name='changeForAll' onChange={this.handelCheckboxChange} checked={taskForEdit.changeForAll} /></p> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-light' onClick={this.handelModelClose}>
                            Cancel
                        </button>
                        <button onClick={this.deleteTask} className='btn btn-danger'>Delete</button>
                    </Modal.Footer>
                </Modal>
            </PageContainer>
        )
    }
};

const mapStateToProps = (state) => ({
    state: state,
    allTasks: state.master.tasks?.data,
    taskCount: state.master.tasks?.count,
});

export default connect(mapStateToProps)(Tasks);