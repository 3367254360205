import actionTypes from '../actions/actionTypes'

const initialState = {
    loading: false,
    authenticated: false,
    error: null,
    currentUser: {},
    users: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.USER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                authenticated: true,
                currentUser: action.user
            }
        case actionTypes.GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.users
            }
        case actionTypes.LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                user: null
            }
        case actionTypes.SIGNOUT_SUCCESS:
            return {
                // ...state,
                authenticated: false,
                currentUser: {},
                loading: false
            };
        default:
            return state;
    }
};