import React from 'react';
import { useEffect, useState } from 'react';
import { Alert, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import { Button, FileInput, FileUploadBtn, Input } from '../../components';
import MultiTextInput from '../../components/MultiTextInput';


const StyledLabel = styled.label`
    font-weight: 600;
    font-size: 11px;
    color: var(--light-text-color);
    line-height: 22px;
    text-transform: uppercase;
    margin: 0;
    margin-top:2px;
`;

const AddEditSDS = ({ closePopUpModal, show, afterSave, selectedSdsForEdit }) => {
    const [sdsDetails, setSDSDetails] = useState({ chemicalName: '', manufacturer: '', cas: [], file: undefined });
    const [loading, setLoading] = useState(false);
    const [saveOrUpdateInProgress, setSaveOrUpdateInProgress] = useState(false);
    const [error, setError] = useState({ apiError: false });

    useEffect(() => {
        if (selectedSdsForEdit) getSDSDetails();
    }, []);

    const getSDSDetails = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get('/api/getSDSDetails/' + selectedSdsForEdit);
            setSDSDetails(payload);
            setLoading(false);
        } catch (error) {

            setLoading(false);
        }
    };

    const onModelChange = (name, value) => {
        if (name === 'cas') value = value.map(val => val.value);
        const temp = { ...sdsDetails };
        temp[name] = value;
        setSDSDetails(temp);
    };

    const handleFileChange = (event) => {
        setSDSDetails((prevState) => ({
            ...prevState,
            file: event.target.files[0]
        }));
    };

    const saveOrUpdate = async (event) => {
        try {
            event.preventDefault();
            setSaveOrUpdateInProgress(true);
            const { file, chemicalName, manufacturer, cas } = sdsDetails;
            const formData = new FormData();
            if (file.lastModified) {
                formData.append('file', file);
            } else {
                formData.append('file', JSON.stringify(file));
            };
            formData.append('chemicalName', chemicalName);
            formData.append('manufacturer', manufacturer);
            formData.append('cas', cas);
            if (selectedSdsForEdit) {
                formData.append('_id', selectedSdsForEdit);
                await axios.put('/api/sdsUpdate', formData);
            } else {
                await axios.post('/api/sdsCreate', formData);
            };
            setSaveOrUpdateInProgress(false);
            afterSave();
        } catch (error) {
            if (error.response && error.response.status === 409) {
                setError({ apiError: true, errorMsg: error.response.data.message });
            };
            console.error(error.response);
            setSaveOrUpdateInProgress(false);
        }
    };

    return (
        <Modal
            centered
            animation={true}
            backdrop="static"
            keyboard={false}
            size='lg' show={show}>
            <Modal.Header >
                <Modal.Title>{sdsDetails._id ? 'Edit ' : 'Add new'} SDS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {loading ?
                        <Container className='text-center my-3'>
                            <Spinner animation="border" variant="success" />
                        </Container> :
                        <form onSubmit={saveOrUpdate}>
                            <Container style={{ maxHeight: "68vh", overflowY: "auto" }}>
                                <Row>
                                    <Col lg={6} md={12} sm={12}>
                                        <Input required={true} onChange={(e) => onModelChange('chemicalName', e.target.value)} label='Chemical Name' type='text' placeholder='Chemical Name' value={sdsDetails.chemicalName} />
                                    </Col>
                                    <Col lg={6} md={12} sm={12}>
                                        <Input required={true} onChange={(e) => onModelChange('manufacturer', e.target.value)} label='Manufacturer' type='text' placeholder='Manufacturer' value={sdsDetails.manufacturer} />
                                    </Col>
                                    <Col lg={6} md={12} sm={12}>
                                        <StyledLabel>Subsidiary Ingredient CAS Number(s):</StyledLabel>
                                        <MultiTextInput
                                            name='cas'
                                            data={sdsDetails.cas && sdsDetails.cas.map((task) => {
                                                return { label: task, value: task };
                                            })}
                                            onChange={(e) => onModelChange('cas', e)}
                                            placeholder='Type a number and press enter...'
                                        />
                                    </Col>
                                    <Col lg={6} md={12} sm={12}>
                                        <StyledLabel>File</StyledLabel>
                                        <br />
                                        <FileUploadBtn>
                                            {sdsDetails.file?.name ? sdsDetails.file.name : 'Choose File to Upload...'}
                                            <FileInput
                                                name='recordFiles'
                                                onChange={handleFileChange}
                                                type='file'
                                                accept=".pdf"
                                                required={!selectedSdsForEdit}
                                            />
                                        </FileUploadBtn>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                {error.apiError &&
                                    <Row>
                                        <Col>
                                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger"
                                                onClose={() => setError({ ...error, apiError: false, errorMsg: '' })} dismissible>
                                                <p className='w-100 mb-0'>{error.errorMsg ? error.errorMsg : sdsDetails._id ? 'Error at updating SDS.' : 'Error at adding SDS.'}</p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                }
                                {error.dataError &&
                                    <Row>
                                        <Col>
                                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                                <p className='w-100 mb-0'>{error.errorMsg}</p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                }
                                <Row className='mt-2'>
                                    <Col>
                                        <Button className='my-0' type='button' onClick={() => closePopUpModal()}>Cancel</Button>
                                    </Col>
                                    <Col>
                                        <Button disabled={saveOrUpdateInProgress} className='my-0' type='submit'>{sdsDetails._id ? (saveOrUpdateInProgress ? 'Updating...' : 'Update') : (saveOrUpdateInProgress ? 'Saving...' : 'Save')}</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </form>
                    }
                </Container>
            </Modal.Body>
        </Modal >
    );
}

export default AddEditSDS;