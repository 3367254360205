import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from '../WithRouter';
import Spinner from '../Spinner';
import { getAdminData, updateAdminData } from '../../actions/adminAction';
import { FormContainer, Input, SearchButton, DetailsContainer, DetailsHeader, StyledWrapper, ButtonContainer, PasswordChangeForm } from '../';
import { checkDataIsValid } from '../../CheckData';

/**
 * Basic Details
 *
 * @class BasicDetails
 * @extends {Component}
 * @category Pages
 * @component
 * @hideconstructor
 */

class BasicDetails extends Component {
    state = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        edit: false
    };

    componentDidMount() {
        const { currentUser: { user }, dispatch } = this.props;
        dispatch(getAdminData(user));
    };
    componentWillReceiveProps = (updatedProps) => {
        const { admin } = updatedProps;
        this.setState({ ...admin })
    };
    handleInputChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value.trimStart() });
    };
    handleSubmit = async (event) => {
        const { currentUser: { user }, dispatch } = this.props;
        event.preventDefault();
        const { firstName, lastName, email, phone } = this.state;
        const isValid = checkDataIsValid({ 'First Name': firstName, 'Last Name': lastName, email: email, 'Phone Number': phone });
        if (isValid) {
            const data = await dispatch(updateAdminData(user, this.state));
            if (data) {
                this.setState({
                    edit: false
                });
                dispatch(getAdminData(user));
            };
        }
    };
    cancelEdit = () => {
        const { admin } = this.props;
        const { edit } = this.state;
        this.setState({ ...admin, edit: !edit });
    };
    render() {
        const { email, firstName, lastName, phone, isLoading, edit } = this.state;
        return (
            <FormContainer margin='0%' width='528px' >
                {isLoading && <Spinner />}
                <PasswordChangeForm onSubmit={this.handleSubmit}>
                {/* <form onSubmit={this.handleSubmit}> */}
                    <DetailsContainer >
                        <DetailsHeader>
                            <h2>Basic Details</h2>
                            <ButtonContainer width='30%'>
                                <SearchButton type='button' onClick={this.cancelEdit} > {!edit ? 'Edit' : 'Cancel'}</SearchButton>
                                <SearchButton type='submit' disabled={!edit} >Save</SearchButton>
                            </ButtonContainer>
                        </DetailsHeader>
                        <StyledWrapper>
                            <Input
                                label='First Name'
                                type='text'
                                name='firstName'
                                onChange={this.handleInputChange}
                                value={firstName}
                                placeholder='First Name'
                                required
                                disable={!edit}
                            />
                            <Input
                                label='Last Name'
                                type='text'
                                name='lastName'
                                onChange={this.handleInputChange}
                                value={lastName}
                                placeholder='Last Name'
                                required
                                disable={!edit}
                            />
                            <Input
                                label='Email Address'
                                type='text'
                                name='email'
                                onChange={this.handleInputChange}
                                value={email}
                                placeholder='Email'
                                required
                                disable={!edit}
                            />
                            <Input
                                label='Phone Number'
                                type='number'
                                name='phone'
                                onChange={this.handleInputChange}
                                value={phone}
                                placeholder='Phone Number'
                                required
                                disable={!edit}
                            />

                        </StyledWrapper>
                    </DetailsContainer>
                    </PasswordChangeForm>
            </FormContainer>
        );
    }
}
const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
    admin: state.admin.admin
});

export default withRouter(connect(mapStateToProps)(BasicDetails));

