import axios from 'axios'
import actions from './actionTypes';
import { apiUrl } from '../helpers';
import { toast } from 'react-toastify';

const getTasksSuccess = (tasks) => (dispatch) => {
    dispatch({
        type: actions.GET_TASKS_SUCCESS,
        tasks
    });
};
const getTasksError = () => (dispatch) => {
    dispatch({
        type: actions.GET_TASKS_ERROR
    });
};
const saveTasksError = () => (dispatch) => {
    dispatch({
        type: actions.SAVE_TASKS_ERROR
    });
};
const getEquipmentsSuccess = (equipments) => (dispatch) => {
    dispatch({
        type: actions.GET_EQUIPMENTS_SUCCESS,
        equipments
    });
};
const getEquipmentsError = () => (dispatch) => {
    dispatch({
        type: actions.GET_EQUIPMENTS_ERROR
    });
};
const getRolesSuccess = (roles) => (dispatch) => {
    dispatch({
        type: actions.GET_ROLES_SUCCESS,
        roles
    });
};
const getRolesError = () => (dispatch) => {
    dispatch({
        type: actions.GET_ROLES_ERROR
    });
};
const getDefaultoptionsSuccess = (defaultoptions) => (dispatch) => {
    dispatch({
        type: actions.GET_DEFAULTOPTIONS_SUCCESS,
        defaultoptions
    });
};
const getDefaultLocationTypeError = () => (dispatch) => {
    dispatch({
        type: actions.GET_DEFAULTOPTIONS_ERROR
    });
};
const getDefaultLocationTypeSuccess = (defaultLocationType) => (dispatch) => {
    dispatch({
        type: actions.GET_DEFAULT_LOCATION_TYPE_SUCCESS,
        defaultLocationType
    });
};
const getDefaultoptionsError = () => (dispatch) => {
    dispatch({
        type: actions.GET_DEFAULT_LOCATION_TYPE_ERROR
    });
};
const masterRequest = () => (dispatch) => {
    dispatch({
        type: actions.MASTER_REQUEST
    });
};
export const getAllTasks = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order, } = params;
        let apiEndPoint = `/api/tasks`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + name;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getTasksSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getTasksError());
    }
};
export const updateTask = (task, stateDetails) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.put(apiUrl(`/api/tasks`), { task, stateDetails });
        toast.success('Task data updated successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in task update.');
        dispatch(saveTasksError());
        return false;
    }
};
export const deleteTask = (task) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.put(apiUrl(`/api/task/delete`), task);
        toast.success('Task deleted successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in task update.')
        dispatch(saveTasksError());
        return false;
    }
};
export const saveNewTask = (task) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.post(apiUrl(`/api/tasks`), { task });
        toast.success('Task save successfully.')
        return payload;
    } catch (error) {
        toast.error('Error in task saving.');
        dispatch(saveTasksError());
        return false;
    }
}
export const getAllEquipments = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order } = params;
        let apiEndPoint = `/api/equipments`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + name;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getEquipmentsSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getEquipmentsError());
    }
};
export const updateEquipments = (equipments) => async (dispatch) => {
    try {
        const { data: { payload } } = await axios.put(apiUrl(`/api/equipments`), { equipments: equipments });
        toast.success('Equipment data updated successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in equipment update.')
        return false;
    }
};
export const getAllRoles = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order } = params;
        let apiEndPoint = `/api/roles`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + name;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getRolesSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getRolesError());
    }
};
export const updateRoles = (roles) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.put(apiUrl(`/api/roles`), { roles: roles });
        toast.success('Role data updated successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in role update.')
        return false;
    }
};
export const getAllDefaultoptions = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order } = params;
        let apiEndPoint = `/api/defaultoptions`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + encodeURIComponent(name);
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getDefaultoptionsSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getDefaultoptionsError());
    }
};
export const updateDefaultoptions = (defaultOption) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.put(apiUrl(`/api/defaultoptions`), { defaultOption: defaultOption });
        toast.success('Default option updated successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Default option update.')
        return false;
    }
};
export const getAllDefaultLocationType = (params) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { pageSize, pageNumber, name, sortBy, order } = params;
        let apiEndPoint = `/api/defaultLocationType`;
        if (pageSize || pageNumber || name) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (name) {
            apiEndPoint += '&name=' + encodeURIComponent(name);
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getDefaultLocationTypeSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getDefaultLocationTypeError());
    }
};
export const updateDefaultLocationType = (defaultLocationType) => async (dispatch) => {
    try {
        dispatch(masterRequest());
        const { data: { payload } } = await axios.put(apiUrl(`/api/defaultLocationType`), { defaultLocationType: defaultLocationType });
        toast.success('Default location type updated successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in Default location type update.')
        return false;
    }
};