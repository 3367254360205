import axios from 'axios'
import actions from './actionTypes';
import { apiUrl } from '../helpers';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const loginSuccess = (user) => async (dispatch) => {
    await dispatch({
        type: actions.LOGIN_SUCCESS,
        user
    });
};
const signoutSuccess = () => ({
    type: actions.SIGNOUT_SUCCESS
});
const loginError = (err) => async (dispatch) => {
    dispatch({
        type: actions.LOGIN_ERROR,
        error: err
    });
};
const getAdminDataSuccess = (admin) => (dispatch) => {
    dispatch({
        type: actions.GET_ADMIN_SUCCESS,
        admin
    });
};
const adminRequest = () => (dispatch) => {
    dispatch({
        type: actions.ADMIN_REQUEST
    });
};
const getAdminDataError = () => (dispatch) => {
    dispatch({
        type: actions.GET_ADMIN_ERROR
    });
};
export const loginAttempt = (userData) => async (dispatch) => {
    try {
        const { data: { payload } } = await axios.post(apiUrl('/api/login'), userData);
        await dispatch(loginSuccess(payload));
        return payload;
    } catch (err) {
        dispatch(loginError(err));
        return false;
    }
};
export const signOut = () => async (dispatch) => {
    try {
        Cookies.remove('adminToken');
        await dispatch(signoutSuccess());
        localStorage.removeItem('adminState');
    } catch (err) { }
};
export const getAdminData = (user) => async (dispatch) => {
    try {
        dispatch(adminRequest());
        const { data: { payload } } = await axios.get(apiUrl('/api/admin/' + user._id));
        dispatch(getAdminDataSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getAdminDataError());
        return false;
    }
};
export const updateAdminData = (user, data) => async () => {
    try {
        const { data: { payload } } = await axios.put(apiUrl('/api/admin/' + user._id), data);
        toast.success('Admin details updated successfully.')
        return payload;
    } catch (error) {
        if (error.response.status === 409) {
            toast.error('User email already exists.');
            return false;
        }
        toast.error('Error in update admin details.');
        return false;
    }
};
export const changePassword = (data) => async () => {
    try {
        const { data: { payload } } = await axios.put(apiUrl('/api/changePassword'), data);
        toast.success('Password changed successfully.');
        return payload;
    } catch (error) {
        toast.error('Error in change password.');
        return false;
    }
};
export const forgotPassword = (email) => async () => {
    try {
        const { data } = await axios.post(apiUrl(`/api/forgotPassword`), { email: email });
        toast.success('Password reset email sent to ' + email);
        return data;
    } catch (error) {
        if (error.response.status === 404) {
            toast.error('User not found.');
            return false;
        }
        return false;
    }
};
export const resetPassword = ({ password, resetToken }) => async () => {
    try {
        const { data } = await axios.post(apiUrl(`/api/resetPassword`), { password, resetToken });
        toast.success('Password reset successfully.');
        return data;
    } catch (error) {
        if (error.response.status === 404) {
            toast.error('User not found.');
            return false;
        }
        return false;
    }
};