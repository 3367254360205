import actionTypes from '../actions/actionTypes'

const initialState = {
    loading: false,
    records: {}
};
export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.RECORD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actionTypes.GET_RECORDS_SUCCESS:
            return {
                ...state,
                loading: false,
                records: action.records
            }
        case actionTypes.GET_RECORDS_ERROR:
            return {
                ...state,
                loading: false,
                records: {}
            }
        default:
            return state;
    }
};