import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from '../components/WithRouter';
import { ProfileContainer, TabContainer, AccountContainer, ListComponent } from '../components';
import BasicDetails from '../components/Account/BasicDetails';
import Password from '../components/Account/Password';

/**
 * Login Page
 *
 * @class Login
 * @extends {Component}
 * @category Pages
 * @component
 * @hideconstructor
 */

class Account extends Component {
    state = {
        currentPanel: 'details'
    };

    componentDidMount() {

    };
    handlePanelClick = (component) => {
        this.setState({ currentPanel: component });
    };
    render() {
        const { currentPanel } = this.state;
        return (
            <AccountContainer>
                <ProfileContainer>
                    <TabContainer>
                        <ul>
                            <ListComponent selected={currentPanel === 'details'} onClick={() => this.handlePanelClick('details')}>Basic Details</ListComponent>
                            <ListComponent selected={currentPanel === 'password'}
                                onClick={() => this.handlePanelClick('password')}
                            >
                                Password & Security
                            </ListComponent>
                        </ul>
                    </TabContainer>
                </ProfileContainer>
                {currentPanel === 'details' && <BasicDetails />}
                {currentPanel === 'password' && <Password />}
            </AccountContainer>
        );
    }
}

export default withRouter(connect()(Account));

