import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate';
import {
    Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, SubHeader, FilterContainer, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput,
    Icon, ArrowButton, ButtonContainer, SearchButton, PageCountContainer
} from '../components';
import { downloadRecord, getRecords } from '../actions/recordAction';
import moment from 'moment';
import jwtSimple from "jwt-simple";
import { withRouter } from '../components/WithRouter';
import Spinner from '../components/Spinner';
import { DropDownStyle, pageSizeOptions } from '../helpers';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import DownloadIcon from '../assets/images/download.svg';

class Record extends Component {
    state = {
        records: [],
        pageNumber: 0,
        pageSize: 15,
        pageCount: 0,
        totalCount: 0,
        sortBy: 'lowerRecordName',
        order: 'asc',
        name: ''
    };
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getRecords({}));
    };
    componentWillReceiveProps = (updatedProps) => {
        const { pageSize } = this.state;
        const { records, recordsCount } = updatedProps;
        const pageCount = Math.ceil(recordsCount / pageSize);
        this.setState({
            records: records,
            totalCount: recordsCount,
            pageCount: pageCount
        });
    };
    handlePageClick = async (event) => {
        const { dispatch } = this.props;
        const { pageSize } = this.state;
        this.setState({
            pageNumber: event.selected + 1
        });
        await dispatch(getRecords({ ...this.state, pageNumber: event.selected + 1, pageSize: pageSize }));
    };
    handleFilterInputChange = async (value) => {
        this.setState({
            pageNumber: 1,
            pageSize: 15,
            name: value
        });
    };
    getFilteredData = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        await dispatch(getRecords({ ...this.state }));
    };
    resetFilter = async () => {
        const { dispatch } = this.props;
        this.setState({
            pageNumber: 0,
            pageSize: 15,
            sortBy: 'lowerRecordName',
            order: 'asc',
            name: ''
        });
        dispatch(getRecords({ pageNumber: 0, pageSize: 15, }));
    };
    handlePageSizeInputChanges = async (value) => {
        const { dispatch } = this.props;
        this.setState({
            pageSize: value,
            pageNumber: 1,
        });
        await dispatch(getRecords({ ...this.state, pageNumber: 1, pageSize: value }));
    };
    handleSortByAndOrderChange = async (sortBy, order) => {
        const { dispatch } = this.props;
        this.setState({
            sortBy,
            order,
            pageNumber: 1,
            pageSize: 15
        });
        await dispatch(getRecords({ ...this.state, pageNumber: 1, pageSize: 15, sortBy, order }));
    };
    goTo = async (url, id) => {
        const { REACT_APP_JWT_SECRET } = process.env;
        const { navigate } = this.props;
        navigate(url + '?userId=' + jwtSimple.encode(id, REACT_APP_JWT_SECRET));
    };
    downloadData = async () => {
        try {
            const { dispatch } = this.props;
            await dispatch(downloadRecord({ ...this.state }));
        } catch (error) {
            console.log("🚀 ~ Record ~ downloadData= ~ error:", error)
        }
    };
    render() {
        const { records, pageSize, pageCount, pageNumber, sortBy, order, name, totalCount } = this.state;
        const { loading } = this.props;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;
        return (
            <PageContainer>
                {loading && <Spinner />}
                <SubHeader>
                    <FilterContainer>
                        <InputContainer>
                            <StyledLabel>Record Name</StyledLabel>
                            <StyledInput
                                type="text"
                                value={name}
                                onChange={(e) => this.handleFilterInputChange(e.target.value)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <StyledLabel>&ensp;</StyledLabel>
                            <ButtonContainer>
                                <Button variant='success' disabled={!name.length} type='submit' onClick={this.getFilteredData} className='mx-1' >
                                    <Icon className='fa fa-search'></Icon>
                                </Button>
                                <Button variant='secondary' onClick={this.resetFilter} className='mx-1' type='button'>
                                    <Icon className='fa fa-undo'></Icon>
                                </Button>
                                <Button variant='primary' onClick={this.downloadData} className='mx-1' type='button'>
                                    <img src={DownloadIcon} alt="" />
                                </Button>
                            </ButtonContainer>
                        </InputContainer>
                    </FilterContainer>
                    <PageCountContainer>
                        <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                    </PageCountContainer>
                    <PageSizeContainer>
                        <StyledLabel>&ensp;</StyledLabel>
                        <div>
                            <Select
                                styles={DropDownStyle}
                                defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                options={pageSizeOptions}
                                onChange={(e) => this.handlePageSizeInputChanges(e.value)}
                            />
                        </div>
                    </PageSizeContainer>
                </SubHeader>
                <TableContainer>
                    <Table className="table  table-bordered ">
                        <TableHead>
                            <TableRow>
                                <TableHeaderElement className='w-30' scope="col">Record Name
                                    {sortBy !== 'lowerRecordName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerRecordName', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'lowerRecordName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerRecordName', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'lowerRecordName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerRecordName', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-25' scope="col">Completed By
                                    {sortBy !== 'lowerCompletedBy' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerCompletedBy', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'lowerCompletedBy' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerCompletedBy', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'lowerCompletedBy' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerCompletedBy', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15 text-center' scope="col">Completed On
                                    {sortBy !== 'completedAt' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('completedAt', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'completedAt' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('completedAt', 'desc')} className='bi bi-sort-numeric-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'completedAt' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('completedAt', 'asc')} className='bi bi-sort-numeric-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15 text-center' scope="col">Approved
                                    {sortBy !== 'isApproved' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('isApproved', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'desc' && sortBy === 'isApproved' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('isApproved', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                    {order === 'asc' && sortBy === 'isApproved' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('isApproved', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15 text-center' scope="col">Completed
                                    {sortBy !== 'isCompleted' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('isCompleted', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'desc' && sortBy === 'isCompleted' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('isCompleted', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                    {order === 'asc' && sortBy === 'isCompleted' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('isCompleted', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                </TableHeaderElement>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {records && records.length && records.map((record) =>
                                <TableRow key={record._id}>
                                    <TableDataElement className='w-30'>{record.name}</TableDataElement>
                                    <TableDataElement className='w-25'>{
                                        record.completedBy ? record.completedBy.firstName + ' ' + record.completedBy.lastName : '--'}{record.completedBy && record.completedBy._id && <ArrowButton title='View user details' onClick={(e) => this.goTo('/users', record.completedBy._id)}></ArrowButton>}
                                    </TableDataElement>
                                    <TableDataElement className='w-15 text-center'>
                                        {record.completedAt ? moment(record.completedAt).format('YYYY-MM-DD  h:mm:ss') : '--'}
                                    </TableDataElement>
                                    <TableDataElement className='w-15 text-center'>
                                        {record.isApproved ? 'Yes' : 'No'}
                                    </TableDataElement>
                                    <TableDataElement className='w-15 text-center'>
                                        {record.isCompleted ? 'Yes' : 'No'}
                                    </TableDataElement>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationContainer>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item hide"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link nextAndPrev"
                        nextClassName="page-item"
                        nextLinkClassName="page-link nextAndPrev"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                    />
                </PaginationContainer>
            </PageContainer>
        )
    }
};

const mapStateToProps = (state) => ({
    records: state.records.records?.data,
    recordsCount: state.records.records?.count,
    loading: state.records.loading
});

export default withRouter(connect(mapStateToProps)(Record));