import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate';
import { downloadUsers, getUsers, loginAsUser, updateUser } from '../actions/userAction';
import moment from 'moment';
import jwtSimple from "jwt-simple";
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios'

import Spinner from '../components/Spinner';
import { Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, SubHeader, FilterContainer, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput, Icon, ButtonContainer, ArrowButton, NoDataFound, PageCountContainer } from '../components';
import { DropDownStyle, apiUrl, pageSizeOptions } from '../helpers';
import { withRouter } from '../components/WithRouter';
import { checkDataIsValid } from '../CheckData';
import DownloadIcon from '../assets/images/download.svg';

class User extends Component {
    state = {
        allUsers: [],
        pageNumber: 0,
        pageSize: 15,
        pageCount: 0,
        totalCount: 0,
        sortBy: 'lowerName',
        order: 'asc',
        nameOrEmail: '',
        edit: false,
        userForEdit: {},
        practiceId: '',
        locationId: '',
        userId: '',
        isModalOpen: false
    };
    componentDidMount = () => {
        const { dispatch } = this.props;
        const params = new URLSearchParams(window.location.search);
        const { REACT_APP_JWT_SECRET } = process.env;
        let practiceId = params.get('practiceId') || '';
        let locationId = params.get('locationId') || '';
        let userId = params.get('userId') || '';
        if (practiceId) {
            practiceId = jwtSimple.decode(practiceId, REACT_APP_JWT_SECRET);
            this.setState({ practiceId });
        };
        if (locationId) {
            locationId = jwtSimple.decode(locationId, REACT_APP_JWT_SECRET);
            this.setState({ locationId });
        };
        if (userId) {
            userId = jwtSimple.decode(userId, REACT_APP_JWT_SECRET);
            this.setState({ userId });
        };
        dispatch(getUsers({ ...this.state, practiceId, locationId, userId }));
    };
    componentWillReceiveProps = (updatedProps) => {
        const { pageSize } = this.state;
        const { users, userCount } = updatedProps;
        const pageCount = Math.ceil(userCount / pageSize);
        this.setState({
            allUsers: users,
            totalCount: userCount,
            pageCount: pageCount
        });
    };
    handlePageClick = async (event) => {
        const { dispatch } = this.props;
        const { pageSize } = this.state;
        this.setState({
            pageNumber: event.selected + 1
        });
        await dispatch(getUsers({ ...this.state, pageNumber: event.selected + 1, pageSize: pageSize }));
    };
    handleFilterInputChange = async (value) => {
        this.setState({
            pageNumber: 1,
            pageSize: 15,
            nameOrEmail: value
        });
    };
    getFilteredData = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        await dispatch(getUsers({ ...this.state }));
    };
    resetFilter = async () => {
        const { dispatch } = this.props;
        this.setState({
            pageNumber: 0,
            pageSize: 15,
            edit: false,
            userForEdit: {},
            sortBy: 'lowerName',
            order: 'asc',
            nameOrEmail: ''
        });
        dispatch(getUsers({ pageNumber: 0, pageSize: 15, }));
    };
    handlePageSizeInputChanges = async (value) => {
        const { dispatch } = this.props;
        this.setState({
            pageSize: value,
            pageNumber: 1
        });
        await dispatch(getUsers({ ...this.state, pageNumber: 1, pageSize: value }));
    };
    handleSortByAndOrderChange = async (sortBy, order) => {
        const { dispatch } = this.props;
        this.setState({
            sortBy,
            order,
            pageNumber: 1,
            pageSize: 15
        });
        await dispatch(getUsers({ ...this.state, pageNumber: 1, pageSize: 15, sortBy, order }));
    };
    handleUserDataChange = (value) => {
        let { userForEdit } = this.state;
        this.setState({
            userForEdit: { ...userForEdit, email: value }
        });
    };
    updateUser = async () => {
        const { dispatch } = this.props;
        const { userForEdit } = this.state;
        const isValid = checkDataIsValid({ email: userForEdit.email });
        if (isValid) {
            const data = await dispatch(updateUser(userForEdit));
            if (data) {
                await dispatch(getUsers(this.state));
                this.setState({
                    edit: false,
                    userForEdit: {}
                });
            }
        };
    };
    disableEnableUser = async () => {
        const { dispatch, loginUser: { user } } = this.props;
        const { userForEdit } = this.state;
        if (userForEdit._id === user._id) {
            toast.error('You can,t disable yourself.');
        } else {
            await dispatch(updateUser(userForEdit));
            await dispatch(getUsers(this.state));
        }
        this.handelModelClose();
    };
    handelModelClose = () => {
        this.setState({
            userForEdit: {},
            isModalOpen: false,
        });
    };
    goTo = async (url, id) => {
        const { REACT_APP_JWT_SECRET } = process.env;
        const { navigate } = this.props;
        navigate(url + '?userId=' + jwtSimple.encode(id, REACT_APP_JWT_SECRET));
    };
    loginAsNormalUser = async (email) => {
        const { dispatch } = this.props;
        const result = await dispatch(loginAsUser(email));
        if (result) {
            const { REACT_APP_OCOORD_APP_URL, REACT_APP_JWT_SECRET } = process.env;
            const data = jwtSimple.encode({ email: result.email, password: result.hashedPassword }, REACT_APP_JWT_SECRET);
            const url = REACT_APP_OCOORD_APP_URL + '/signin?data=' + data;
            window.open(url, '_blank')
        }
    };

    downloadData = async () => {
        try {
            const { dispatch } = this.props;
            await dispatch(downloadUsers({ ...this.state }));
        } catch (error) {
            console.error("🚀 ~ User ~ downloadData= ~ error:", error);
        }
    };

    render() {
        const { allUsers, pageCount, pageSize, sortBy, order, pageNumber, userForEdit, edit, isModalOpen, nameOrEmail, totalCount } = this.state;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;
        const { loading } = this.props;
        return (
            <PageContainer>
                {loading && <Spinner />}
                <SubHeader>
                    <FilterContainer>
                        <InputContainer>
                            <StyledLabel>User Name/Email</StyledLabel>
                            <StyledInput
                                type="text"
                                value={nameOrEmail}
                                onChange={(e) => this.handleFilterInputChange(e.target.value)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <StyledLabel>&ensp;</StyledLabel>
                            <ButtonContainer>
                                <Button variant='success' disabled={!nameOrEmail.length} type='submit' onClick={this.getFilteredData} className='mx-1' >
                                    <Icon className='fa fa-search'></Icon>
                                </Button>
                                <Button variant='secondary' onClick={this.resetFilter} className='mx-1' type='button'>
                                    <Icon className='fa fa-undo'></Icon>
                                </Button>
                                <Button variant='primary' onClick={this.downloadData} className='mx-1' type='button'>
                                    <img src={DownloadIcon} alt="" />
                                </Button>
                            </ButtonContainer>
                        </InputContainer>
                    </FilterContainer>
                    <PageCountContainer>
                        <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                    </PageCountContainer>
                    <PageSizeContainer>
                        <StyledLabel>&ensp;</StyledLabel>
                        <div>
                            <Select
                                styles={DropDownStyle}
                                defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                options={pageSizeOptions}
                                value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                onChange={(e) => this.handlePageSizeInputChanges(e.value)}
                            />
                        </div>
                    </PageSizeContainer>
                </SubHeader>
                <TableContainer>
                    <Table className="table  table-bordered ">
                        <TableHead>
                            <TableRow>
                                <TableHeaderElement className='w-20' scope="col">User Name
                                    {sortBy !== 'lowerName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerName', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'lowerName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerName', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'lowerName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerName', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15' scope="col">Practice Name   {sortBy !== 'lowerPracticeName' &&
                                    <Icon onClick={() => this.handleSortByAndOrderChange('lowerPracticeName', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'lowerPracticeName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerPracticeName', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'lowerPracticeName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerPracticeName', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-8 text-center' scope="col">Admin
                                    {sortBy !== 'adminLocationsCount' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('adminLocationsCount', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'adminLocationsCount' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('adminLocationsCount', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'adminLocationsCount' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('adminLocationsCount', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-20' scope="col">User Email
                                    {sortBy !== 'email' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('email', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'email' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('email', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'email' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('email', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-9 text-center' scope="col">Phone
                                    {sortBy !== 'phone' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('phone', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'phone' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('phone', 'desc')} className='bi bi-sort-numeric-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'phone' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('phone', 'asc')} className='bi bi-sort-numeric-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-13 text-center' scope="col">Last Login
                                    {sortBy !== 'lastLogin' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lastLogin', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'lastLogin' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lastLogin', 'desc')} className='bi bi-sort-numeric-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'lastLogin' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lastLogin', 'asc')} className='bi bi-sort-numeric-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-10 text-center' scope="col">Locations   {sortBy !== 'locCount' &&
                                    <Icon onClick={() => this.handleSortByAndOrderChange('locCount', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'locCount' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('locCount', 'desc')} className='bi bi-sort-numeric-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'locCount' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('locCount', 'asc')} className='bi bi-sort-numeric-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-5 text-center' scope="col">Action</TableHeaderElement>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allUsers && allUsers.map((user) =>
                                <TableRow rowColor={user.deleted ? '#ff020269' : null} key={user._id}>
                                    <TableDataElement className='w-20'>{user.fullName}</TableDataElement>
                                    <TableDataElement className='w-15'>{user.practice ? user.practice.name : '--'}</TableDataElement>
                                    <TableDataElement className='w-8 text-center'>{user.adminLocations && user.adminLocations.length ? 'Yes' : 'No'}</TableDataElement>
                                    {edit && userForEdit._id === user._id ?
                                        <TableDataElement className='w-20'>
                                            <StyledInput
                                                className='w-100'
                                                type='text'
                                                value={userForEdit.email ? userForEdit.email : ''}
                                                onChange={(e) => this.handleUserDataChange(e.target.value)}
                                            />
                                        </TableDataElement> :
                                        <TableDataElement className='w-20'>
                                            {user.email ? user.email : '--'}  {user.deleted === false && <ArrowButton onClick={() => this.loginAsNormalUser(user.email)} title='Log in as this user'></ArrowButton>}
                                        </TableDataElement>
                                    }
                                    <TableDataElement className='w-9 text-center'> {user.phone ? user.phone : '--'}
                                    </TableDataElement>
                                    <TableDataElement className='w-13 text-center'>{user.lastLogin ? moment(user.lastLogin).format('YYYY-MM-DD  h:mm') : '--'}</TableDataElement>
                                    <TableDataElement className='w-10 text-center'>
                                        <ArrowButton
                                            onClick={(e) => this.goTo('/location', user._id)}
                                        >{user.locations && user.locations.length ? user.locations.length : 0}</ArrowButton>
                                    </TableDataElement>
                                    <TableDataElement className='w-5 text-center'>
                                        {edit && userForEdit._id === user._id ?
                                            <>
                                                <Icon onClick={() => this.updateUser()} className='fa fa-save text-success'></Icon>
                                                <Icon className='fa fa-undo text-danger' onClick={(e) => this.setState({ edit: true, userForEdit: {} })}></Icon>
                                            </> :
                                            user.deleted ?
                                                <Icon
                                                    className='bi bi-person-x-fill text-danger' title='Inactive User' /> :
                                                <>
                                                    <Icon className='fa fa-pencil text-success' onClick={(e) => this.setState({ edit: true, userForEdit: user })} />
                                                    {
                                                        !user.isActive ?
                                                            <Icon
                                                                onClick={(e) => this.setState({
                                                                    userForEdit: { ...user, isActive: true },
                                                                    isModalOpen: true
                                                                })}
                                                                className='bi bi-person-x-fill text-danger' title='Inactive User' />
                                                            :
                                                            <Icon
                                                                onClick={(e) => this.setState({
                                                                    userForEdit: { ...user, isActive: false },
                                                                    isModalOpen: true
                                                                })}
                                                                className='bi bi-person-check-fill text-success' title='Active User' />
                                                    }
                                                </>}
                                    </TableDataElement>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {
                        (!allUsers || !allUsers.length) &&
                        <NoDataFound>{loading ? 'Loading...' : 'No Data Found'}</NoDataFound>
                    }
                </TableContainer>
                <PaginationContainer>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item hide"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link nextAndPrev"
                        nextClassName="page-item"
                        nextLinkClassName="page-link nextAndPrev"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                    />
                </PaginationContainer>
                <Modal
                    show={isModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Do you want to do this action?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <button className='btn btn-danger' onClick={this.handelModelClose}>
                            Discard and close
                        </button>
                        <button onClick={this.disableEnableUser} className='btn btn-success'>Submit and close</button>
                    </Modal.Footer>
                </Modal>
            </PageContainer>
        );
    };
};

const mapStateToProps = (state) => ({
    userCount: state.user.users?.count,
    users: state.user.users?.data,
    loginUser: state.user.currentUser,
    loading: state.user.loading
});

export default withRouter(connect(mapStateToProps)(User));