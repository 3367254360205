import React from 'react';
import { useEffect, useState } from 'react';
import { Alert, Col, Container, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import { Button } from '../../components';


const StyledLabel = styled.label`
    font-weight: 600;
    font-size: 11px;
    color: var(--light-text-color);
    line-height: 22px;
    text-transform: uppercase;
    margin: 0;
    margin-top:2px;
`;

const RejectRequest = ({ closePopUpModal, show, afterSave, requestData: { _id, chemicalName, cas, manufacturer } }) => {
    const [comment, setComment] = useState('');
    const [saveOrUpdateInProgress, setSaveOrUpdateInProgress] = useState(false);
    const [error, setError] = useState({ apiError: false });

    useEffect(() => {
    }, []);

    const onModelChange = (value) => {
        setComment(value);
    };

    const saveSdsAndCompleteRequest = async (event) => {
        try {
            event.preventDefault();
            setSaveOrUpdateInProgress(true);
            await axios.put('/api/updateSdsRequest', { _id, chemicalName, manufacturer, status: 'Rejected', comment });
            setSaveOrUpdateInProgress(false);
            afterSave();
        } catch (error) {
            console.error(error);
            setSaveOrUpdateInProgress(false);
        }
    };

    return (
        <Modal
            centered
            animation={true}
            backdrop="static"
            keyboard={false}
            size='lg' show={show}>
            <Modal.Header >
                <Modal.Title>Discarding <strong>{chemicalName}</strong> SDS request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form onSubmit={saveSdsAndCompleteRequest}>
                        <Container style={{ maxHeight: "68vh", overflowY: "auto" }}>
                            <Row>
                                <Col lg='12'>
                                    <StyledLabel>Comment (Reason of discarding)</StyledLabel>
                                    <br />
                                    <textarea className='w-100 p-2' required onChange={(e) => onModelChange(e.target.value)} placeholder='Write here your comment' value={comment} id="" />
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            {error.apiError &&
                                <Row>
                                    <Col>
                                        <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger"
                                            onClose={() => setError({ ...error, apiError: false, errorMsg: '' })} dismissible>
                                            <p className='w-100 mb-0'>{error.errorMsg ? error.errorMsg : 'Error at discarding request.'}</p>
                                        </Alert>
                                    </Col>
                                </Row>
                            }
                            {error.dataError &&
                                <Row>
                                    <Col>
                                        <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                            <p className='w-100 mb-0'>{error.errorMsg}</p>
                                        </Alert>
                                    </Col>
                                </Row>
                            }
                            <Row className='mt-2'>
                                <Col>
                                    <Button className='my-0' type='button' onClick={() => closePopUpModal()}>Cancel</Button>
                                </Col>
                                <Col>
                                    <Button disabled={saveOrUpdateInProgress} className='my-0' type='submit'>{saveOrUpdateInProgress ? 'Discarding...' : 'Discard'}</Button>
                                </Col>
                            </Row>
                        </Container>
                    </form>
                </Container>
            </Modal.Body>
        </Modal >
    );
}

export default RejectRequest;