import React, { Component } from 'react'
import { signOut, forgotPassword } from '../actions/adminAction'
import { connect } from 'react-redux';
import OcoordLogo from '../assets/images/logo-icon-flat.svg';
import Cookies from 'js-cookie';
import { withRouter } from '../components/WithRouter';
import Spinner from '../components/Spinner';
import { FormContainer, LoginHeader, LoginForm, Input, Button, StyledLink } from '../components';


/**
 * RequestToPasswordReset Page
 *
 * @class RequestToPasswordReset
 * @extends {Component}
 * @category Pages
 * @component
 * @hideconstructor
 */
class RequestToPasswordReset extends Component {
    state = {
        email: '',
        isLoading: false
    };
    componentDidMount() {
        if (Cookies.get('adminToken')) {
            this.props.dispatch(signOut());
        }
    };
    handleInputChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    };
    handleSubmit = async (event) => {
        const { dispatch, navigate } = this.props;
        event.preventDefault();
        this.setState({
            isLoading: true
        });
        const { email } = this.state;
        const data = await dispatch(forgotPassword(email));
        if (data) {
            navigate('/login');
        }
        this.setState({
            isLoading: false
        });
    };
    render() {
        const { email, isLoading } = this.state;
        return (
            <FormContainer >
                {isLoading && <Spinner />}
                <LoginHeader>
                    <img src={OcoordLogo} alt='ocoord-logo' />
                    <h2>Forgot Password</h2>
                    <p style={{ fontSize: '12px' }}>Enter the email address of your account.
                        We’ll send you a link to reset your password.</p>
                </LoginHeader>
                <LoginForm onSubmit={this.handleSubmit}>
                    <Input
                        label='Email'
                        type='email'
                        name='email'
                        onChange={this.handleInputChange}
                        value={email}
                        placeholder='you@example.com'
                        required
                    />
                    <Button type='submit'>Continue</Button>
                </LoginForm>
                <StyledLink to='/login'>Sign In</StyledLink>
            </FormContainer>
        );
    }
};

export default withRouter(connect()(RequestToPasswordReset));

