import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  color: var(--dark-text-color);
  font-weight: 400;
  background: white;
  border: 0.5px solid #b9bcca;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  padding:  8px;
  transition: border 0.15s linear, box-shadow 0.15s linear;
  margin-top: 2px;
  margin-bottom: 2px;
  width: 100%;
`;
const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
`;
const StyledSubtext = styled.p`
  font-size: 11px;
  color: var(--light-text-color);
  margin-left: 8px;
  margin-top: 5px;
`;

export const Input = ({ label, placeholder, name, type, onChange, value, required, subtext, className, style, disable }) => {
  return (
    <React.Fragment>
      {label &&
        <StyledLabel>{label}{required && <StyledLabel style={{ color: "red", marginLeft: "3px" }}>*</StyledLabel>}</StyledLabel>
      }
      {disable ?
        <StyledInput
          name={name}
          type={type}
          onChange={(e) => onChange(e)}
          value={value}
          tabIndex='0'
          required={required}
          placeholder={placeholder}
          className={className}
          style={style}
          disabled
        />
        :
        <StyledInput
          name={name}
          type={type}
          onChange={(e) => onChange(e)}
          value={value}
          tabIndex='0'
          required={required}
          placeholder={placeholder}
          className={className}
          style={style}
        />
      }
      {subtext && <StyledSubtext>{subtext}</StyledSubtext>}
    </React.Fragment>
  );
};
