import { toast } from "react-toastify";

export const checkDataIsValid = (data) => {
    let valid = true;
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    for (const item in data) {
        if (valid) {
            if (item === 'email') {
                if (!data[item].trimStart()) {
                    valid = false;
                    toast.error('Email can not be empty.');
                } else {
                    valid = emailPattern.test(data[item]);
                    if (!valid) {
                        toast.error('Invalid email id.');
                    };
                }
            };
            if (item === 'Phone Number') {
                if (!data[item].trimStart()) {
                    valid = false;
                    toast.error('Phone Number can not be empty.');
                } else if (data[item].length !== 10) {
                    valid = false;
                    toast.error('Invalid Phone Number .');
                };
            };
            if (!data[item].trimStart()) {
                toast.error(`${item} can not be empty`);
                valid = false;
            };
        };
    };
    return valid;
};