import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import axios from 'axios'
import moment from 'moment';

import { withRouter } from '../../components/WithRouter';
import { apiUrl, DropDownStyle, pageSizeOptions } from '../../helpers'

import { Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, SubHeader, FilterContainer, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput, Icon, ButtonContainer, SearchButton, NoDataFound, PageCountContainer } from '../../components';

import approveRequest from '../../assets/images/approve-document.svg';
import discardRequest from '../../assets/images/discard-document.svg';
import AcceptRequest from './AcceptRequest';
import RejectRequest from './RejectRequest';

class ViewSdsRequest extends Component {
    state = {
        userRequestsList: [],
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        sortBy: 'lowerChemicalName',
        order: 'asc',
        chemicalName: '',
        loading: false,
        requestData: {},
        acceptRequestModalOpen: false,
        discardRequestModalOpen: false,
    };

    componentDidMount = () => {
        this.getSdsRequests();
    };

    getSdsRequests = async () => {
        try {
            this.setState({ loading: true });
            const { pageSize, pageNumber, chemicalName, sortBy, order } = this.state;
            let apiEndPoint = `/api/getSdsRequests`;
            if (pageSize) {
                apiEndPoint += '?pageSize=' + pageSize;
            };
            if (pageNumber) {
                apiEndPoint += '&pageNumber=' + pageNumber;
            };
            if (chemicalName) {
                apiEndPoint += '&chemicalName=' + encodeURIComponent(chemicalName);
            };
            if (sortBy && order) {
                apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
            };
            const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
            const pageCount = Math.ceil(payload.count / pageSize);
            this.setState({ userRequestsList: payload.data, pageCount, totalCount: payload.count, loading: false });
        } catch (error) {
            console.error(error);
            this.setState({ loading: false });
        };
    };

    onPageChange = async (event) => {
        this.setState({ pageNumber: event.selected + 1 }, () => {
            this.getSdsRequests();
        });
    };

    getFilteredData = (e) => {
        e.preventDefault();
        this.setState({ pageNumber: 1, pageSize: 10 }, () => {
            this.getSdsRequests();
        });
    };

    resetFilter = () => {
        this.setState({ pageNumber: 0, pageSize: 10, sortBy: 'lowerChemicalName', order: 'asc', chemicalName: '' }, () => {
            this.getSdsRequests();
        });
    };

    handlePageSizeChange = (value) => {
        this.setState({ pageSize: value, pageNumber: 1 }, () => {
            this.getSdsRequests();
        });
    };

    sort = (sortBy, order) => {
        this.setState({ sortBy, order, pageNumber: 1 }, () => {
            this.getSdsRequests();
        });
    };

    afterSave = () => {
        this.setState({ pageNumber: 1, pageSize: 10, acceptRequestModalOpen: false, discardRequestModalOpen: false }, () => {
            this.getSdsRequests();
        });
    };

    acceptRequest = (e, request) => {
        this.setState({ requestData: request, acceptRequestModalOpen: true });
    };

    discardRequest = (e, request) => {
        this.setState({ requestData: request, acceptRequestModalOpen: false, discardRequestModalOpen: true });
    };

    render() {
        const { userRequestsList, pageCount, pageSize, pageNumber, sortBy, order, chemicalName, totalCount, loading, acceptRequestModalOpen, requestData, discardRequestModalOpen } = this.state;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;

        return (
            <>
                <SubHeader>
                    <FilterContainer>
                        <InputContainer>
                            <StyledLabel>Chemical Name</StyledLabel>
                            <StyledInput
                                type="text"
                                value={chemicalName}
                                onChange={(e) => this.setState({ chemicalName: e.target.value })}
                            />
                        </InputContainer>
                        <InputContainer>
                            <StyledLabel>&ensp;</StyledLabel>
                            <ButtonContainer>
                                <SearchButton disabled={!chemicalName.length} type='submit' onClick={this.getFilteredData} width='20%' >
                                    <Icon className='fa fa-search'></Icon>
                                </SearchButton>
                                <SearchButton reset={true} onClick={this.resetFilter} width='20%' type='button'>
                                    <Icon className='fa fa-undo'></Icon>
                                </SearchButton>
                            </ButtonContainer>
                        </InputContainer>
                    </FilterContainer>
                    <PageCountContainer>
                        <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                    </PageCountContainer>
                    <PageSizeContainer>
                        <StyledLabel>&ensp;</StyledLabel>
                        <div>
                            <Select
                                styles={DropDownStyle}
                                defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                options={pageSizeOptions}
                                onChange={(e) => this.handlePageSizeChange(e.value)}
                            />
                        </div>
                    </PageSizeContainer>
                </SubHeader>
                <TableContainer>
                    <Table className="table table-bordered">
                        <TableHead>
                            <TableRow>
                                <TableHeaderElement className='w-20' scope="col">Chemical Name
                                    {sortBy !== 'lowerChemicalName' &&
                                        <Icon onClick={() => this.sort('lowerChemicalName', 'asc')} className='bi bi-arrow-down-up text-dark' />}
                                    {order === 'asc' && sortBy === 'lowerChemicalName' &&
                                        <Icon onClick={() => this.sort('lowerChemicalName', 'desc')} className='bi bi-sort-alpha-down text-success' />}
                                    {order === 'desc' && sortBy === 'lowerChemicalName' &&
                                        <Icon onClick={() => this.sort('lowerChemicalName', 'asc')} className='bi bi-sort-alpha-up text-success' />}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-20' scope="col">Manufacturer
                                    {sortBy !== 'lowerManufacturer' &&
                                        <Icon onClick={() => this.sort('lowerManufacturer', 'asc')} className='bi bi-arrow-down-up text-dark' />}
                                    {order === 'asc' && sortBy === 'lowerManufacturer' &&
                                        <Icon onClick={() => this.sort('lowerManufacturer', 'desc')} className='bi bi-sort-alpha-down text-success' />}
                                    {order === 'desc' && sortBy === 'lowerManufacturer' &&
                                        <Icon onClick={() => this.sort('lowerManufacturer', 'asc')} className='bi bi-sort-alpha-up text-success' />}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15' scope="col">CAS
                                    {sortBy !== 'cas' &&
                                        <Icon onClick={() => this.sort('cas', 'asc')} className='bi bi-arrow-down-up text-dark' />
                                    }
                                    {order === 'asc' && sortBy === 'cas' &&
                                        <Icon onClick={() => this.sort('cas', 'desc')} className='bi bi-sort-alpha-down text-success' />
                                    }
                                    {order === 'desc' && sortBy === 'cas' &&
                                        <Icon onClick={() => this.sort('cas', 'asc')} className='bi bi-sort-alpha-up text-success' />
                                    }
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15' scope="col">
                                    Requested By
                                    {sortBy !== 'requestedBy.fullName' &&
                                        <Icon onClick={() => this.sort('requestedBy.fullName', 'asc')} className='bi bi-arrow-down-up text-dark' />
                                    }
                                    {order === 'asc' && sortBy === 'requestedBy.fullName' &&
                                        <Icon onClick={() => this.sort('requestedBy.fullName', 'desc')} className='bi bi-sort-alpha-down text-success' />
                                    }
                                    {order === 'desc' && sortBy === 'requestedBy.fullName' &&
                                        <Icon onClick={() => this.sort('requestedBy.fullName', 'asc')} className='bi bi-sort-alpha-up text-success' />
                                    }
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15' scope="col">
                                    Requested At
                                    {sortBy !== 'requestedAt' &&
                                        <Icon onClick={() => this.sort('requestedAt', 'asc')} className='bi bi-arrow-down-up text-dark' />
                                    }
                                    {order === 'asc' && sortBy === 'requestedAt' &&
                                        <Icon onClick={() => this.sort('requestedAt', 'desc')} className='bi bi-sort-alpha-down text-success' />
                                    }
                                    {order === 'desc' && sortBy === 'requestedAt' &&
                                        <Icon onClick={() => this.sort('requestedAt', 'asc')} className='bi bi-sort-alpha-up text-success' />
                                    }
                                </TableHeaderElement>
                                <TableHeaderElement className='w-10' scope="col">
                                    Status
                                </TableHeaderElement>
                                <TableHeaderElement className='w-10 text-center' scope="col">
                                    Action
                                </TableHeaderElement>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userRequestsList && userRequestsList.map((request) =>
                                <TableRow key={request._id}>
                                    <TableDataElement className='w-20'>
                                        {request.chemicalName}
                                    </TableDataElement>
                                    <TableDataElement className='w-20'>
                                        {request.manufacturer}
                                    </TableDataElement>
                                    <TableDataElement className='w-10'>
                                        {request.cas && request.cas.length ? request.cas.toString() : '- -'}
                                    </TableDataElement>
                                    <TableDataElement className='w-15'>
                                        {request.requestedBy?.fullName || '--'}
                                    </TableDataElement>
                                    <TableDataElement className='w-15'>
                                        {moment(request.requestedAt).format('YYYY-MM-DD hh:mm')}
                                    </TableDataElement>
                                    <TableDataElement className='w-10'>
                                        {request.status}
                                    </TableDataElement>
                                    <TableDataElement className='w-10 text-center'>
                                        <span className='d-flex'>
                                            <button disabled={request.status !== 'Pending'} onClick={(e) => this.acceptRequest(e, request)} type='button' className='btn me-2 p-0 border-0'>
                                                <img height={25} src={approveRequest} alt="Accept request" className='' />
                                            </button>
                                            <button onClick={(e) => this.discardRequest(e, request)} disabled={request.status !== 'Pending'} type='button' className='btn p-0 border-0'>
                                                <img height={25} src={discardRequest} alt="Discard request" />
                                            </button>
                                        </span>
                                    </TableDataElement>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {
                        (!userRequestsList || !userRequestsList.length) &&
                        <NoDataFound>{loading ? 'Loading...' : 'No Data Found'}</NoDataFound>
                    }
                </TableContainer>
                <PaginationContainer>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={this.onPageChange}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item hide"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link nextAndPrev"
                        nextClassName="page-item"
                        nextLinkClassName="page-link nextAndPrev"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                    />
                </PaginationContainer>
                {acceptRequestModalOpen && <AcceptRequest requestData={requestData} show={acceptRequestModalOpen} closePopUpModal={() => { this.setState({ acceptRequestModalOpen: false }) }} afterSave={this.afterSave} />}
                {discardRequestModalOpen && <RejectRequest requestData={requestData} show={discardRequestModalOpen} closePopUpModal={() => { this.setState({ discardRequestModalOpen: false }) }} afterSave={this.afterSave} />}
            </>
        );
    };
};

const mapStateToProps = (state) => ({

});

export default withRouter(connect(mapStateToProps)(ViewSdsRequest));