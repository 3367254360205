import styled from 'styled-components';

export const TableContainer = styled.div`
    overflow-y:auto;
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : '81vh')};
     @media (max-width: 767px) {
            table {
                display: block;
                width: 100%;
            }
            thead,
            tbody,
            thead th {
                display: block;
            }
            thead th:last-child {
                border-bottom: none;
            }
            thead {
                float: left;
                z-index: 10;
            }
            tbody {
                width: auto;
                position: relative;
                overflow-x: auto;
            }
            td,
            th {
                padding: 20px .625em .625em .625em;
                height: 60px;
                vertical-align: middle;
                box-sizing: border-box;
                overflow-x: hidden;
                overflow-y: hidden;
                width: 120px !important;
                font-size: 12px !important;
                text-overflow: ellipsis;
                .bi{
                    font-size: 12px !important;
                }
            }
            thead th {
                text-align: left !important;
                border-bottom: 1px solid #f7f7f9;
            }
            tbody tr {
                display: table-cell;
            }
            tr td {
                // background: #F8F8F8;
                border-right: 1px solid #1A2929;
                border-bottom: 1px solid #1A2929;
            }
            tbody td {
                display: block;
                text-align: center;
            }
        }
`;
export const Table = styled.table`
    font-size:16px;
`;
export const TableHead = styled.thead`
    background: #d4d4d4;
    top: 0;
    position: sticky;
    tr{
        border-width: 0;
    }
`;
export const TableBody = styled.tbody`

`;
export const TableRow = styled.tr`
   ${({ rowColor }) => (rowColor ? 'background-color:' + rowColor : '')};
    width: 100%;
`;
export const TableHeaderElement = styled.th`
    justify-content:between;
`;
export const TableDataElement = styled.td`
    word-break: break-word;
`;
