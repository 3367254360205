import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAllEquipments, updateEquipments } from '../../actions/masterAction';
import ReactPaginate from 'react-paginate';
import {
    Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, SubHeader, FilterContainer, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput, Icon, Checkbox, ButtonContainer, SearchButton, NoDataFound, PageCountContainer
} from '../';
import Modal from 'react-bootstrap/Modal';
import { DropDownStyle, pageSizeOptions } from '../../helpers';
import Select from 'react-select';
import { checkDataIsValid } from '../../CheckData';

class Equipments extends Component {
    state = {
        equipments: [],
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        sortBy: 'name',
        order: 'asc',
        equipmentForEdit: {},
        edit: false,
        isModalOpen: false,
        name: ''
    };
    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(getAllEquipments({ ...this.state }));
    };
    componentWillReceiveProps = (updatedProps) => {
        const { pageSize } = this.state;
        const { allEquipments, equipmentsCount } = updatedProps;
        const pageCount = Math.ceil(equipmentsCount / pageSize);
        this.setState({
            equipments: allEquipments,
            totalCount: equipmentsCount,
            pageCount: pageCount
        });
    };

    handlePageClick = async (event) => {
        const { dispatch } = this.props;
        const { pageSize } = this.state;
        this.setState({
            pageNumber: event.selected + 1
        });
        await dispatch(getAllEquipments({ ...this.state, pageNumber: event.selected + 1, pageSize: pageSize }));
    };
    handleFilterInputChange = async (value) => {
        this.setState({
            pageNumber: 1,
            pageSize: 10,
            name: value
        });
    };
    getFilteredData = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        await dispatch(getAllEquipments({ ...this.state }));
    };
    resetFilter = async () => {
        const { dispatch } = this.props;
        this.setState({
            pageNumber: 0,
            pageSize: 10,
            edit: false,
            equipmentForEdit: {},
            sortBy: 'name',
            order: 'asc',
            name: ''
        });
        dispatch(getAllEquipments({ pageNumber: 0, pageSize: 10, }));
    };
    handlePageSizeInputChanges = async (value) => {
        const { dispatch } = this.props;
        value = value > 0 ? value : 1;
        this.setState({
            pageSize: value,
            pageNumber: 1,
        });
        await dispatch(getAllEquipments({ ...this.state, pageNumber: 1, pageSize: value }));
    };
    handleSortByAndOrderChange = async (sortBy, order) => {
        const { dispatch } = this.props;
        this.setState({
            sortBy,
            order,
            pageNumber: 1,
            pageSize: 10
        });
        await dispatch(getAllEquipments({ ...this.state, pageNumber: 1, pageSize: 10, sortBy, order }));
    };
    handelEditRow = (task) => {
        this.setState({
            edit: true,
            equipmentForEdit: task
        });
    };
    handelModelClose = () => {
        const { equipmentForEdit } = this.state;
        this.setState({
            isModalOpen: false,
            equipmentForEdit: { ...equipmentForEdit, changeForAll: false }
        });
    };
    handelCheckboxChange = ({ target: { checked, name } }) => {
        const { equipmentForEdit } = this.state;
        this.setState({
            equipmentForEdit: { ...equipmentForEdit, changeForAll: checked }
        });
    };
    handelInputChange = (value, name) => {
        const { equipmentForEdit } = this.state;
        this.setState({
            equipmentForEdit: { ...equipmentForEdit, [name]: value.trimStart() }
        });
    };
    updateEquipments = async () => {
        const { dispatch } = this.props;
        const { equipmentForEdit } = this.state;
        const isValid = checkDataIsValid({ 'Equipment Name': equipmentForEdit.name });
        if (isValid) {
            const data = await dispatch(updateEquipments(equipmentForEdit));
            if (data) {
                await dispatch(getAllEquipments(this.state));
                this.setState({
                    edit: false,
                    isModalOpen: false
                });
            };
        };
    };
    render() {
        const { equipments, pageSize, pageCount, pageNumber, sortBy, order, equipmentForEdit, edit, isModalOpen, name, totalCount } = this.state;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;
        return (
            <PageContainer width='100%'>
                <SubHeader>
                    <FilterContainer>
                        <InputContainer>
                            <StyledLabel>Equipments</StyledLabel>
                            <StyledInput
                                type="text"
                                value={name}
                                onChange={(e) => this.handleFilterInputChange(e.target.value)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <StyledLabel>&ensp;</StyledLabel>
                            <ButtonContainer>
                                <SearchButton disabled={!name.length} type='submit' onClick={this.getFilteredData} width='20%' >
                                    <Icon className='fa fa-search'></Icon>
                                </SearchButton>
                                <SearchButton reset={true} onClick={this.resetFilter} width='20%' type='button'>
                                    <Icon className='fa fa-undo'></Icon>
                                </SearchButton>
                            </ButtonContainer>
                        </InputContainer>
                    </FilterContainer>
                    <PageCountContainer>
                        <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                    </PageCountContainer>
                    <PageSizeContainer>
                        <StyledLabel>&ensp;</StyledLabel>
                        <div>
                            <Select
                                styles={DropDownStyle}
                                defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                options={pageSizeOptions}
                                onChange={(e) => this.handlePageSizeInputChanges(e.value)}
                            />
                        </div>
                    </PageSizeContainer>
                </SubHeader>
                <TableContainer maxHeight='73vh'>
                    <Table className="table  table-bordered ">
                        <TableHead>
                            <TableRow>
                                <TableHeaderElement className='w-80' scope="col">Equipments
                                    {sortBy !== 'name' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'name' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('name', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'name' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-20 text-center' scope="col">Action </TableHeaderElement>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {equipments && equipments.map((task) =>
                                <TableRow key={task._id}>
                                    {edit && equipmentForEdit._id === task._id ?
                                        <TableDataElement className='w-80'>
                                            <StyledInput name='name' onChange={(e) => this.handelInputChange(e.target.value, 'name')} value={equipmentForEdit.name ? equipmentForEdit.name : ''} />
                                        </TableDataElement> :
                                        <TableDataElement className='w-80'>{
                                            task.name ? task.name : '--'}
                                        </TableDataElement>
                                    }
                                    <TableDataElement className='w-20 text-center'>{edit && equipmentForEdit._id === task._id ?
                                        <>
                                            <Icon
                                                onClick={() => this.setState({
                                                    isModalOpen: true
                                                })}
                                                // onClick={this.updateEquipments}
                                                className='fa fa-save text-success' />
                                            <Icon className='fa fa-undo text-danger' onClick={(e) => this.setState({ edit: false })} />
                                        </> :
                                        <Icon onClick={() => this.handelEditRow(task)} className='fa fa-pencil text-success' />}
                                    </TableDataElement>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {
                        (!equipments || !equipments.length) &&
                        <NoDataFound>No Data Found</NoDataFound>
                    }
                </TableContainer>
                <PaginationContainer>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item hide"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link nextAndPrev"
                        nextClassName="page-item"
                        nextLinkClassName="page-link nextAndPrev"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                    />
                </PaginationContainer>
                <Modal
                    show={isModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Do you want to do this action?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Apply for all existing and future practices <Checkbox name='changeForAll' onChange={this.handelCheckboxChange} checked={equipmentForEdit.changeForAll} />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-danger' onClick={this.handelModelClose}>
                            Discard and close
                        </button>
                        <button onClick={this.updateEquipments} className='btn btn-success'>Submit and close</button>
                    </Modal.Footer>
                </Modal>
            </PageContainer>
        )
    }
};
const mapStateToProps = (state) => ({
    allEquipments: state.master.equipments.data,
    equipmentsCount: state.master.equipments.count,
});

export default connect(mapStateToProps)(Equipments);
