import axios from 'axios'
import actions from './actionTypes';
import { apiUrl } from '../helpers';
import { toast } from 'react-toastify';

const getLocationsSuccess = (locations) => (dispatch) => {
    dispatch({
        type: actions.GET_LOCATIONS_SUCCESS,
        locations
    });
};
const getLocationsError = () => (dispatch) => {
    dispatch({
        type: actions.GET_LOCATIONS_ERROR
    });
};
const locationRequest = () => (dispatch) => {
    dispatch({
        type: actions.LOCATION_REQUEST
    });
};
export const getLocations = (params) => async (dispatch) => {
    try {
        dispatch(locationRequest());
        const { pageSize, pageNumber, locName, sortBy, order, practiceId, userId, inComplete, isLibraryStepUpdated } = params;
        let apiEndPoint = `/api/allLocation`;
        if (pageSize || pageNumber || locName || practiceId || userId) {
            apiEndPoint += '?';
        };
        if (pageNumber) {
            apiEndPoint += 'pageNumber=' + pageNumber;
        };
        if (pageSize) {
            apiEndPoint += '&pageSize=' + pageSize;
        };
        if (locName) {
            apiEndPoint += '&name=' + locName;
        };
        if (practiceId) {
            apiEndPoint += '&practice=' + practiceId;
        };
        if (userId) {
            apiEndPoint += '&user=' + userId;
        };
        if (inComplete) {
            apiEndPoint += '&inComplete=' + inComplete;
        };
        if (isLibraryStepUpdated) {
            apiEndPoint += '&isLibraryStepUpdated=' + isLibraryStepUpdated;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getLocationsSuccess(payload));
        return payload;
    } catch (error) {
        dispatch(getLocationsError());
        return false;
    }
};
export const downloadLocations = (params) => async () => {
    try {
        const { locName, sortBy, order, practiceId, userId, inComplete, isLibraryStepUpdated } = params;
        let apiEndPoint = `/api/export/locations?`;

        if (sortBy && order) {
            apiEndPoint += 'sortBy=' + sortBy + '&order=' + order;
        };
        if (locName) {
            apiEndPoint += '&name=' + locName;
        };
        if (practiceId) {
            apiEndPoint += '&practice=' + practiceId;
        };
        if (userId) {
            apiEndPoint += '&user=' + userId;
        };
        if (inComplete) {
            apiEndPoint += '&inComplete=' + inComplete;
        };
        if (isLibraryStepUpdated) {
            apiEndPoint += '&isLibraryStepUpdated=' + isLibraryStepUpdated;
        };

        const { data } = await axios.get(apiUrl(apiEndPoint), { responseType: 'blob' });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([data]));

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Locations.xlsx'); // Set the file name

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up by removing the link and revoking the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.log("🚀 ~ downloadLocations ~ error:", error)
        return false;
    }
};

export const startEmbedding = (location) => async () => {
    try {
        const { data } = await axios.put(apiUrl('/api/location/embedding'), { location });
        return data;
    } catch (error) {
        console.error(error);
        toast.error('Error in emending.');
        return false;
    }
}