import styled from 'styled-components';

export const DetailsContainer = styled.div`
display: inline-block;
max-height:75vh;
overflow-y:auto;
flex-direction: column;
justify-content: center;
width: 515px;
padding: 0px 50px;
@media (max-width:768px) {
  display:block;
width:100%;
  padding-left:0px;
  padding-right: 0px;    
    }
    @media only screen and (max-width:1024px) and (min-width: 769px){
      display:block;
      width:470px;
        padding-left:23px;
        padding-right: 13px; 
    
    }
`;
export const DetailsHeader = styled.div`
  padding: 15px 0px 15px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;

  h2{
    @media (max-width:480px) {
        font-size:18px;
          white-space: nowrap;
  margin-right: 7px;
        }
  }
`;
export const StyledWrapper = styled.div`
  max-width: 215px;
`;
export const TabContainer = styled.div`
  text-align: left;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 15px 40px 15px 50px;
  @media (max-width:480px) {
    display:block;
    width:100%;
    padding-left:10px;
    padding-right: 10px;

      }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
  }
`;
export const ListComponent = styled.li`
      list-style-type: none;
      color:${(props) => (props.selected ? `var(--key-color)` : 'black')};
      font-weight:${(props) => (props.selected ? `600` : '400')};
      margin: 5px 0;
      font-size:12px;
      cursor: pointer;
`;
export const AccountContainer = styled.div`
display: flex;
align-items: flex-start;
align-self: self-start;

@media only screen and (max-width:480px){
  display: block;
  margin-left:1rem;
 }
 @media only screen and (max-width:768px) and (min-width: 481px){
  display:block;
  margin-top:2rem;
  margin-left:0;
 }
@media only screen and (max-width:1024px) and (min-width: 769px){
  margin-top: 2rem;
  margin-left: 0;
  display:block;

}
@media only screen and (max-width:1440px) and (min-width: 1201px){
  margin-top: 2%;
  margin-left:-24%;
 }
 @media only screen and (max-width:1200px) and (min-width: 1025px){
  margin-top:2%;
  margin-left: -8%;
 }
 @media only screen and (max-width:2560px) and (min-width: 1991px){
  margin-top: 4%;
  margin-left: -43%;
 }
 @media only screen and (max-width:1900px) and (min-width: 1441px){
  margin-top:2%;
  margin-left: -30%;
 }
`;
export const ProfileContainer = styled.div`
  text-align: center;
  min-width: 285px;
  margin-right: 25px;
  @media (max-width:480px) {
        min-width:210px;
      }
      @media only screen and (max-width:768px) and (min-width: 481px){
        min-width:400px;
       }
  img{
    margin:10px 0 5px 0;
  }
  @media (max-width:1024px) {
    margin-right:0;
    margin-bottom:2rem;
      }
  h2,
  p {
    margin: 0;
  }
  h2 {
    margin: 15px 0;
  }
  p {
    margin-bottom: 25px;

    @media (max-width:768px) {
      text-align:center;
      width:100%;
  }
  img {
    width: 67px;
    height: 67px;
    margin-top: 25px;
  }
`;