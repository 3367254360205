import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAllDefaultLocationType, updateDefaultLocationType } from '../../actions/masterAction';
import ReactPaginate from 'react-paginate';
import {
    Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, SubHeader, FilterContainer, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput, Icon, ButtonContainer, SearchButton, NoDataFound, SubPaginationContainer, PageCountContainer
} from '../';
import Select from 'react-select';
import { pageSizeOptions } from '../../helpers';
import { checkDataIsValid } from '../../CheckData';
import Modal from 'react-bootstrap/Modal';

class DefaultLocationType extends Component {
    state = {
        defaultLocationType: [],
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        sortBy: 'name',
        order: 'asc',
        defaultLocationTypeForEdit: {},
        edit: false,
        name: ''
    };
    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(getAllDefaultLocationType({ ...this.state }));
    };
    componentWillReceiveProps = (updatedProps) => {
        const { pageSize } = this.state;
        const { allDefaultLocationType, defaultLocationTypeCount } = updatedProps;
        const pageCount = Math.ceil(defaultLocationTypeCount / pageSize);
        this.setState({
            defaultLocationType: allDefaultLocationType,
            totalCount: defaultLocationTypeCount,
            pageCount: pageCount
        });
    };
    handlePageClick = async (event) => {
        const { dispatch } = this.props;
        const { pageSize } = this.state;
        this.setState({
            pageNumber: event.selected + 1
        });
        await dispatch(getAllDefaultLocationType({ ...this.state, pageNumber: event.selected + 1, pageSize: pageSize }));
    };
    handleFilterInputChange = async (value) => {
        this.setState({
            pageNumber: 1,
            pageSize: 10,
            name: value
        });
    };
    getFilteredData = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        await dispatch(getAllDefaultLocationType({ ...this.state }));
    };
    resetFilter = async () => {
        const { dispatch } = this.props;
        this.setState({
            pageNumber: 0,
            pageSize: 10,
            edit: false,
            defaultLocationTypeForEdit: {},
            sortBy: 'name',
            order: 'asc',
            name: '',
            isModalOpen: false
        });
        dispatch(getAllDefaultLocationType({ pageNumber: 0, pageSize: 10, }));
    };
    handlePageSizeInputChanges = async (value) => {
        const { dispatch } = this.props;
        value = value > 0 ? value : 1;
        this.setState({
            pageSize: value,
            pageNumber: 1,
        });
        await dispatch(getAllDefaultLocationType({ ...this.state, pageNumber: 1, pageSize: value }));
    };
    handleSortByAndOrderChange = async (sortBy, order) => {
        const { dispatch } = this.props;
        this.setState({
            sortBy,
            order,
            pageNumber: 1,
            pageSize: 10
        });
        await dispatch(getAllDefaultLocationType({ ...this.state, pageNumber: 1, pageSize: 10, sortBy, order }));
    };
    handelEditRow = (task) => {
        this.setState({
            edit: true,
            defaultLocationTypeForEdit: task
        });
    };
    handelInputChange = (value, name) => {
        const { defaultLocationTypeForEdit } = this.state;
        this.setState({
            defaultLocationTypeForEdit: { ...defaultLocationTypeForEdit, [name]: value.trimStart() }
        });
    };
    handelModelClose = () => {
        const { defaultLocationTypeForEdit } = this.state;
        this.setState({
            isModalOpen: false,
            defaultLocationTypeForEdit: { ...defaultLocationTypeForEdit }
        });
    };
    updateDefaultLocationType = async () => {
        const { dispatch } = this.props;
        const { defaultLocationTypeForEdit } = this.state;
        const isValid = checkDataIsValid({ 'Default option Name': defaultLocationTypeForEdit.name });
        if (isValid) {
            const data = await dispatch(updateDefaultLocationType(defaultLocationTypeForEdit));
            if (data) {
                await dispatch(getAllDefaultLocationType(this.state));
                this.setState({
                    edit: false
                });
                this.handelModelClose();
            };
        }
    };
    render() {
        const { defaultLocationType, pageSize, pageCount, pageNumber, sortBy, order, defaultLocationTypeForEdit, edit, name, isModalOpen, totalCount } = this.state;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;
        return (
            <PageContainer width='100%'>
                <SubHeader>
                    <FilterContainer>
                        <InputContainer>
                            <StyledLabel>Default Location Type</StyledLabel>
                            <StyledInput
                                type="text"
                                value={name}
                                onChange={(e) => this.handleFilterInputChange(e.target.value)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <StyledLabel>&ensp;</StyledLabel>
                            <ButtonContainer>
                                <SearchButton disabled={!name.length} type='submit' onClick={this.getFilteredData} width='20%' >
                                    <Icon className='fa fa-search'></Icon>
                                </SearchButton>
                                <SearchButton reset={true} onClick={this.resetFilter} width='20%' type='button'>
                                    <Icon className='fa fa-undo'></Icon>
                                </SearchButton>
                            </ButtonContainer>
                        </InputContainer>
                    </FilterContainer>
                    <PageCountContainer>
                        <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                    </PageCountContainer>
                    <PageSizeContainer>
                        <StyledLabel>&ensp;</StyledLabel>
                        <div>
                            <Select
                                defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                options={pageSizeOptions}
                                onChange={(e) => this.handlePageSizeInputChanges(e.value)}
                            />
                        </div>
                    </PageSizeContainer>
                </SubHeader>
                <TableContainer maxHeight='73vh'>
                    <Table className="table  table-bordered ">
                        <TableHead>
                            <TableRow>
                                <TableHeaderElement className='w-80' scope="col">Default Location Types
                                    {sortBy !== 'name' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'name' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('name', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'name' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-20 text-center' scope="col">Action </TableHeaderElement>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {defaultLocationType && defaultLocationType.map((task) =>
                                <TableRow key={task._id}>
                                    {edit && defaultLocationTypeForEdit._id === task._id ?
                                        <TableDataElement className='w-80'>
                                            <StyledInput name='name' onChange={(e) => this.handelInputChange(e.target.value, 'name')} value={defaultLocationTypeForEdit.name ? defaultLocationTypeForEdit.name : ''} />
                                        </TableDataElement> :
                                        <TableDataElement className='w-80'>{
                                            task.name ? task.name : '--'}
                                        </TableDataElement>
                                    }
                                    <TableDataElement className='w-20 text-center'>{edit && defaultLocationTypeForEdit._id === task._id ?
                                        <>
                                            <Icon
                                                onClick={() => this.setState({
                                                    isModalOpen: true
                                                })}
                                                className='fa fa-save text-success' />
                                            <Icon className='fa fa-undo text-danger' onClick={(e) => this.setState({ edit: false })} />
                                        </> :
                                        <Icon onClick={() => this.handelEditRow(task)} className='fa fa-pencil text-success' />}
                                    </TableDataElement>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {
                        (!defaultLocationType || !defaultLocationType.length) &&
                        <NoDataFound>No Data Found</NoDataFound>
                    }
                </TableContainer>

                <PaginationContainer>
                    <SubPaginationContainer>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next >"
                            onPageChange={this.handlePageClick}
                            pageRangeDisplayed={10}
                            pageCount={pageCount}
                            previousLabel="< Previous"
                            renderOnZeroPageCount={null}
                            forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            activeClassName="active"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                        />
                    </SubPaginationContainer>
                </PaginationContainer>
                <Modal
                    show={isModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Do you want to do this action?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        This action will affect current and future practices.
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-danger' onClick={this.handelModelClose}>
                            Discard and close
                        </button>
                        <button onClick={this.updateDefaultLocationType} className='btn btn-success'>Submit and close</button>
                    </Modal.Footer>
                </Modal>
            </PageContainer>
        )
    }
};
const mapStateToProps = (state) => ({
    allDefaultLocationType: state.master.defaultLocationType?.data,
    defaultLocationTypeCount: state.master.defaultLocationType?.count,
});

export default connect(mapStateToProps)(DefaultLocationType);