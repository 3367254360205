import actionTypes from '../actions/actionTypes'

const initialState = {
    loading: false,
    tasks: {},
    equipments: {},
    roles: {},
    defaultoptions: {},
    defaultLocationType: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.MASTER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actionTypes.GET_TASKS_SUCCESS:
            return {
                ...state,
                loading: false,
                tasks: action.tasks
            }
        case actionTypes.GET_TASKS_ERROR:
            return {
                ...state,
                loading: false,
                tasks: {}
            }
        case actionTypes.SAVE_TASKS_ERROR:
            return {
                ...state,
                loading: false,
                tasks: {}
            }
        case actionTypes.GET_EQUIPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                equipments: action.equipments
            }
        case actionTypes.GET_EQUIPMENTS_ERROR:
            return {
                ...state,
                loading: false,
                equipments: {}
            }
        case actionTypes.GET_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.roles
            }
        case actionTypes.GET_ROLES_ERROR:
            return {
                ...state,
                loading: false,
                roles: {}
            }
        case actionTypes.GET_DEFAULTOPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                defaultoptions: action.defaultoptions
            }
        case actionTypes.GET_DEFAULTOPTIONS_ERROR:
            return {
                ...state,
                loading: false,
                defaultoptions: {}
            }
        case actionTypes.GET_DEFAULT_LOCATION_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                defaultLocationType: action.defaultLocationType
            }
        case actionTypes.GET_DEFAULT_LOCATION_TYPE_ERROR:
            return {
                ...state,
                loading: false,
                defaultLocationType: {}
            }
        default:
            return state;
    }
};