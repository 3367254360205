import actionTypes from '../actions/actionTypes'

const initialState = {
    loading: false,
    error: null,
    admin: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actionTypes.GET_ADMIN_SUCCESS:
            return {
                ...state,
                admin: action.admin,
                loading: false
            }
        case actionTypes.GET_ADMIN_ERROR:
            return {
                ...state,
                admin: {},
                loading: false
            }
        default:
            return state;
    }
};