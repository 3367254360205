import React from 'react';
import { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { PageContainer } from '../../components';
import SDSList from './SDSList';
import ViewSdsRequest from './ViewSdsRequest';

const SDSTabs = () => {
    const [key, setKey] = useState('sdsList');

    return (
        <PageContainer>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="sdsList" title="SDS List">
                    {key === "sdsList" && <SDSList />}
                </Tab>
                <Tab eventKey="requestList" title="Request List">
                    {key === 'requestList' && <ViewSdsRequest />}
                </Tab>
            </Tabs>
        </PageContainer>
    );
}

export default SDSTabs;