import React, { Component } from 'react'
import { resetPassword, signOut } from '../actions/adminAction';
import { connect } from 'react-redux';
import OcoordLogo from '../assets/images/logo-icon-flat.svg';
import Cookies from 'js-cookie';
import { withRouter } from '../components/WithRouter';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import { FormContainer, LoginHeader, LoginForm, Input, Button } from '../components';


/**
 * Reset Password Page
 *
 * @class ResetPassword
 * @extends {Component}
 * @category Pages
 * @component
 * @hideconstructor
 */
class ResetPassword extends Component {
    state = {
        password: '',
        password2: '',
        resetToken: '',
        isLoading: false
    };

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const resetToken = params.get('resetToken') || '';
        this.setState({
            resetToken
        });
        if (Cookies.get('adminToken')) {
            this.props.dispatch(signOut());
        }
    };
    handleInputChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    };
    handleSubmit = async (event) => {
        const { dispatch, navigate } = this.props;
        event.preventDefault();
        const { password, password2 } = this.state;
        if (password === password2) {
            this.setState({
                isLoading: true
            });
            const data = await dispatch(resetPassword(this.state));
            if (data) {
                navigate('/login');
            }
            this.setState({
                isLoading: false
            });
        } else {
            toast.error("Password and confirm password does not match.")
        }
    };
    render() {
        const { password2, password, isLoading } = this.state;
        const isBtnEnable = password === password2 && password.length >= 8;
        return (
            <FormContainer >
                {isLoading && <Spinner />}
                <LoginHeader>
                    <img src={OcoordLogo} alt='ocoord-logo' />
                    <h2>Reset Password</h2>
                </LoginHeader>
                <LoginForm className='mb-4' onSubmit={this.handleSubmit}>
                    <Input
                        label='Password'
                        type='password'
                        name='password'
                        onChange={this.handleInputChange}
                        value={password}
                        placeholder='New Password'
                        subtext='Password must be at least 8 characters.'
                        required
                    />
                    <Input
                        label='Confirm New Password'
                        type='password'
                        name='password2'
                        placeholder='Confirm New Password'
                        onChange={this.handleInputChange}
                        value={password2}
                        required
                    />
                    <Button disabled={!isBtnEnable} type='submit'>Set Password</Button>
                </LoginForm>
            </FormContainer>
        );
    };
};

export default withRouter(connect()(ResetPassword));

