import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate';
import jwtSimple from "jwt-simple";
import Select from 'react-select';
import axios from 'axios'

import { withRouter } from '../../components/WithRouter';
import { apiUrl, DropDownStyle, pageSizeOptions } from '../../helpers'

import { Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, SubHeader, FilterContainer, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput, Icon, ButtonContainer, NoDataFound, PageCountContainer } from '../../components'
import AddEditSDS from './AddSDS';
import ViewSDS from './ViewSDS';
import { Button } from 'react-bootstrap';
import DownloadIcon from '../../assets/images/download.svg';

class SDSList extends Component {
    state = {
        allSafetyDataSheets: [],
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        sortBy: 'lowerChemicalName',
        order: 'asc',
        chemicalName: '',
        loading: false,
        addSDSModalOpen: false,
        viewSdsDocOpen: false,
        selectedSdsForEdit: '',
        sdsDetails: {}
    };

    componentDidMount = () => {
        this.getSdsList();
    };

    getSdsList = async () => {
        try {
            this.setState({ loading: true });
            const { pageSize, pageNumber, chemicalName, sortBy, order } = this.state;
            let apiEndPoint = `/api/getSdsList`;
            if (pageSize) {
                apiEndPoint += '?pageSize=' + pageSize;
            };
            if (pageNumber) {
                apiEndPoint += '&pageNumber=' + pageNumber;
            };
            if (chemicalName) {
                apiEndPoint += '&chemicalName=' + encodeURIComponent(chemicalName);
            };
            if (sortBy && order) {
                apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
            };
            const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
            const pageCount = Math.ceil(payload.count / pageSize);
            this.setState({ allSafetyDataSheets: payload.data, pageCount, totalCount: payload.count, loading: false });
        } catch (error) {
            console.error(error);
            this.setState({ loading: false });
        };
    };

    onPageChange = async (event) => {
        this.setState({ pageNumber: event.selected + 1 }, () => {
            this.getSdsList();
        });
    };

    getFilteredData = (e) => {
        e.preventDefault();
        this.setState({ pageNumber: 1, pageSize: 10 }, () => {
            this.getSdsList();
        });
    };

    resetFilter = () => {
        this.setState({ pageNumber: 0, pageSize: 10, sortBy: 'lowerChemicalName', order: 'asc', chemicalName: '' }, () => {
            this.getSdsList();
        });
    };

    handlePageSizeChange = (value) => {
        this.setState({ pageSize: value, pageNumber: 1 }, () => {
            this.getSdsList();
        });
    };

    sort = (sortBy, order) => {
        this.setState({ sortBy, order, pageNumber: 1 }, () => {
            this.getSdsList();
        });
    };

    closeModal = () => {
        this.setState({ addSDSModalOpen: false, selectedSdsForEdit: '' });
    };

    afterSave = () => {
        this.setState({ addSDSModalOpen: false, pageNumber: 1, pageSize: 10, selectedSdsForEdit: '' }, () => {
            this.getSdsList();
        });
    };

    openModal = () => {
        this.setState({ addSDSModalOpen: true });
    };

    viewSDSDoc = (sdsDetails) => {
        this.setState({ viewSdsDocOpen: true, sdsDetails });
    };

    goTo = async (url, id) => {
        const { REACT_APP_JWT_SECRET } = process.env;
        const { navigate } = this.props;
        navigate(url + '?locationId=' + jwtSimple.encode(id, REACT_APP_JWT_SECRET));
    };

    editSds = (selectedSdsForEdit) => {
        this.setState({ selectedSdsForEdit, addSDSModalOpen: true });
    };

    downloadData = async () => {
        try {
            const { chemicalName, sortBy, order } = this.state;
            let apiEndPoint = `/api/export/sds?`;
            if (sortBy && order) apiEndPoint += 'sortBy=' + sortBy + '&order=' + order;
            if (chemicalName) apiEndPoint += '&chemicalName=' + encodeURIComponent(chemicalName);

            const { data } = await axios.get(apiUrl(apiEndPoint), { responseType: 'blob' });
            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([data]));

            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'SDS.xlsx'); // Set the file name

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean up by removing the link and revoking the object URL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.log("🚀 ~ Record ~ downloadData= ~ error:", error)
        }
    };

    render() {
        const { allSafetyDataSheets, pageCount, pageSize, pageNumber, sortBy, order, chemicalName, totalCount, loading, addSDSModalOpen, selectedSdsForEdit, viewSdsDocOpen, sdsDetails } = this.state;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;
        return (
            <>
                <SubHeader>
                    <FilterContainer>
                        <InputContainer>
                            <StyledLabel>Chemical Name</StyledLabel>
                            <StyledInput
                                type="text"
                                value={chemicalName}
                                onChange={(e) => this.setState({ chemicalName: e.target.value })}
                            />
                        </InputContainer>
                        <InputContainer>
                            <StyledLabel>&ensp;</StyledLabel>
                            <ButtonContainer>
                                <Button variant='success' disabled={!chemicalName.length} type='submit' onClick={this.getFilteredData} className='mx-1'>
                                    <Icon className='fa fa-search'></Icon>
                                </Button>
                                <Button variant='secondary' onClick={this.resetFilter} className='mx-1' type='button'>
                                    <Icon className='fa fa-undo'></Icon>
                                </Button>
                                <Button variant='primary' onClick={this.downloadData} className='mx-1' type='button'>
                                    <img src={DownloadIcon} alt="" />
                                </Button>
                                <Button variant='success' onClick={this.openModal} className='mx-1' type='button'>
                                    <Icon className='fa fa-plus'></Icon>
                                </Button>
                            </ButtonContainer>
                        </InputContainer>
                    </FilterContainer>
                    <PageCountContainer>
                        <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                    </PageCountContainer>
                    <PageSizeContainer>
                        <StyledLabel>&ensp;</StyledLabel>
                        <div>
                            <Select
                                styles={DropDownStyle}
                                defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                options={pageSizeOptions}
                                onChange={(e) => this.handlePageSizeChange(e.value)}
                            />
                        </div>
                    </PageSizeContainer>
                </SubHeader>
                <TableContainer>
                    <Table className="table  table-bordered">
                        <TableHead>
                            <TableRow>
                                <TableHeaderElement className='w-30' scope="col">Chemical Name
                                    {sortBy !== 'lowerChemicalName' &&
                                        <Icon onClick={() => this.sort('lowerChemicalName', 'asc')} className='bi bi-arrow-down-up text-dark' />}
                                    {order === 'asc' && sortBy === 'lowerChemicalName' &&
                                        <Icon onClick={() => this.sort('lowerChemicalName', 'desc')} className='bi bi-sort-alpha-down text-success' />}
                                    {order === 'desc' && sortBy === 'lowerChemicalName' &&
                                        <Icon onClick={() => this.sort('lowerChemicalName', 'asc')} className='bi bi-sort-alpha-up text-success' />}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-30' scope="col">Manufacturer
                                    {sortBy !== 'lowerManufacturer' &&
                                        <Icon onClick={() => this.sort('lowerManufacturer', 'asc')} className='bi bi-arrow-down-up text-dark' />}
                                    {order === 'asc' && sortBy === 'lowerManufacturer' &&
                                        <Icon onClick={() => this.sort('lowerManufacturer', 'desc')} className='bi bi-sort-alpha-down text-success' />}
                                    {order === 'desc' && sortBy === 'lowerManufacturer' &&
                                        <Icon onClick={() => this.sort('lowerManufacturer', 'asc')} className='bi bi-sort-alpha-up text-success' />}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-25' scope="col">CAS
                                    {sortBy !== 'cas' &&
                                        <Icon onClick={() => this.sort('cas', 'asc')} className='bi bi-arrow-down-up text-dark' />
                                    }
                                    {order === 'asc' && sortBy === 'cas' &&
                                        <Icon onClick={() => this.sort('cas', 'desc')} className='bi bi-sort-alpha-down text-success' />
                                    }
                                    {order === 'desc' && sortBy === 'cas' &&
                                        <Icon onClick={() => this.sort('cas', 'asc')} className='bi bi-sort-alpha-up text-success' />
                                    }
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15 text-center' scope="col">
                                    Action
                                </TableHeaderElement>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allSafetyDataSheets && allSafetyDataSheets.map((safetyDataSheets) =>
                                <TableRow key={safetyDataSheets._id}>
                                    <TableDataElement className='w-30'>
                                        {safetyDataSheets.chemicalName}
                                    </TableDataElement>
                                    <TableDataElement className='w-30'>
                                        {safetyDataSheets.manufacturer}
                                    </TableDataElement>
                                    <TableDataElement className='w-25'>
                                        {safetyDataSheets.cas && safetyDataSheets.cas.length ? safetyDataSheets.cas.toString() : '- -'}
                                    </TableDataElement>
                                    <TableDataElement className='w-15 text-center'>
                                        <Icon onClick={() => this.editSds(safetyDataSheets._id)} className='fa fa-pencil text-success' />
                                        <Icon onClick={() => this.viewSDSDoc(safetyDataSheets)} className='fa fa-file text-danger' />
                                    </TableDataElement>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {
                        (!allSafetyDataSheets || !allSafetyDataSheets.length) &&
                        <NoDataFound>{loading ? 'Loading...' : 'No Data Found'}</NoDataFound>
                    }
                </TableContainer>
                <PaginationContainer>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={this.onPageChange}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item hide"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link nextAndPrev"
                        nextClassName="page-item"
                        nextLinkClassName="page-link nextAndPrev"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                    />
                </PaginationContainer>
                {addSDSModalOpen && <AddEditSDS selectedSdsForEdit={selectedSdsForEdit} show={addSDSModalOpen} closePopUpModal={this.closeModal} afterSave={this.afterSave} />}
                {viewSdsDocOpen && <ViewSDS show={viewSdsDocOpen} sdsDetails={sdsDetails} handleClose={() => this.setState({ viewSdsDocOpen: false })} />}
            </>
        );
    };
};

const mapStateToProps = (state) => ({

});

export default withRouter(connect(mapStateToProps)(SDSList));