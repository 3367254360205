import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Layouts from './Routes/Layouts';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure({
  position: 'bottom-right',
  transition: Zoom
});
const SiteWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 100%;
  background-color: #F3F5F9;
  margin-left: 3rem;
`;

class App extends Component {
  render() {
    return (
      <SiteWrapper >
        <Layouts />
      </SiteWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.user.authenticated,
    user: state.user.currentUser
  };
};

export default connect(mapStateToProps)(App);
