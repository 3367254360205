import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from '../WithRouter';
import Spinner from '../Spinner';
import { changePassword, } from '../../actions/adminAction';
import { FormContainer, Input, DetailsContainer, Button, StyledWrapper, PasswordChangeForm } from '../';


/**
 * Login Page
 *
 * @class Login
 * @extends {Component}
 * @category Pages
 * @component
 * @hideconstructor
 */

class Password extends Component {
    state = {
        password: '',
        password2: '',
    };

    handleInputChange = ({ target, key }) => {
        if (key === 'Enter') {
            this.handleSubmit();
        } else {
            this.setState({ [target.name]: target.value });
        }
    };
    handleSubmit = async (event) => {
        const { dispatch } = this.props;
        event.preventDefault();
        const data = await dispatch(changePassword(this.state));
        if (data) {
            this.setState({
                password: '',
                password2: ''
            });
        };
    };
    render() {
        const { password, password2, isLoading } = this.state;
        const isBtnEnable = password === password2 && password.length >= 8;
        return (
            <FormContainer margin='0%' width='528px' >
                {isLoading && <Spinner />}
                <PasswordChangeForm onSubmit={this.handleSubmit}>
                    <DetailsContainer >
                        <h2>Change Password</h2>
                        <StyledWrapper>
                            <Input
                                label='Password'
                                type='password'
                                name='password'
                                onChange={this.handleInputChange}
                                value={password ? password : ''}
                                placeholder='New Password'
                                subtext='Password must be at least 8 characters.'
                            />
                            <Input
                                label='Password'
                                type='password'
                                name='password2'
                                onChange={this.handleInputChange}
                                value={password2 ? password2 : ''}
                                placeholder='Confirm New Password'
                            />
                            <Button disabled={!isBtnEnable} type='submit'>
                                Set Password
                            </Button>
                        </StyledWrapper>
                    </DetailsContainer>
                </PasswordChangeForm>
            </FormContainer>
        );
    }
}
const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
    admin: state.admin.admin
});

export default withRouter(connect(mapStateToProps)(Password));

