import React, { Component } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyles from '../styles/globalStyle';
import NavBar from '../components/NavBar'
import PrivateRoutes from './Authguard/PrivateRoutes';
import { connect } from 'react-redux';
import User from "../pages/User";
import Login from "../pages/Login";
import Practice from '../pages/Practice';
import Location from '../pages/Location';
import Record from '../pages/Record';
import Master from '../pages/Master';
import ResetPassword from '../pages/ResetPassword';
import RequestToPassword from '../pages/RequestToPassword';
import Account from '../pages/Account';
import { NoMatch } from '../pages/NoMatch';
import SDSTabs from '../pages/SafetyDataSheets/SDSTabs';
import Contentful from '../pages/Contentful';
class Layouts extends Component {
    render() {
        const { authenticated, user } = this.props;
        return (
            <Router>
                <NavBar />
                <GlobalStyles />
                <Routes>
                    <Route element={<PrivateRoutes authenticated={authenticated}
                        user={user} />}>
                        <Route element={<User />} path="/users" exact />
                        <Route element={<Practice />} path="/practice" exact />
                        <Route element={<Location />} path="/location" exact />

                        <Route element={<SDSTabs />} path="/sds" exact />
                        <Route element={<Contentful />} path="/library" exact />
                        <Route element={<Record />} path="/record" exact />
                        <Route element={<Master />} path="/master" exact />
                        <Route element={<Account />} path="/account" exact />
                    </Route>
                    <Route element={<Login />} path="/login" />
                    <Route element={<Login />} path="/" />
                    <Route element={<ResetPassword />} path="/resetPassword" />
                    <Route element={<RequestToPassword />} path="/forgotPassword" />
                    <Route path='*' exact element={<NoMatch />} />
                </Routes>
            </Router>
        )
    };
};
const mapStateToProps = (state) => {
    return {
        authenticated: state.user.authenticated,
        user: state.user.currentUser
    };
};
export default connect(mapStateToProps)(Layouts);