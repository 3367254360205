import React, { Component } from 'react'
import { loginAttempt, signOut } from '../actions/adminAction'
import { connect } from 'react-redux';
import OcoordLogo from '../assets/images/logo-icon-flat.svg';
import Cookies from 'js-cookie';
import { withRouter } from '../components/WithRouter';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import { FormContainer, LoginHeader, LoginForm, Input, Button, StyledLink } from '../components';


/**
 * Login Page
 *
 * @class Login
 * @extends {Component}
 * @category Pages
 * @component
 * @hideconstructor
 */
class Login extends Component {
  state = {
    email: '',
    password: '',
    isLoading: false
  };

  componentDidMount() {
    if (Cookies.get('adminToken')) {
      this.props.dispatch(signOut());
    }
  };
  handleInputChange = ({ target, key }) => {
    if (key === 'Enter') {
      this.handleSubmit();
    } else {
      this.setState({ [target.name]: target.value });
    }
  };
  handleSubmit = async (event) => {
    const { dispatch, navigate } = this.props;
    event.preventDefault();
    this.setState({
      isLoading: true
    });
    const data = await dispatch(loginAttempt(this.state));
    if (data) {
      setTimeout(() => {
        navigate('/users');
        this.setState({
          isLoading: false
        });
      }, 1000);
    } else {
      toast.error('Invalid Email or Password.')
      this.setState({
        isLoading: false
      });
    }

  };
  render() {
    const { email, password, isLoading } = this.state;
    return (
      <FormContainer >
        {isLoading && <Spinner />}
        <LoginHeader>
          <img src={OcoordLogo} alt='ocoord-logo' />
          <h2>Sign In to Ocoord</h2>
          <h2>(Admin)</h2>
        </LoginHeader>
        <LoginForm onSubmit={this.handleSubmit}>
          <Input
            name='email'
            label='email address'
            type='email'
            onChange={this.handleInputChange}
            value={email}
            placeholder='you@example.com'
            required
          />
          <Input
            name='password'
            label='password'
            type='password'
            placeholder='Password'
            onChange={this.handleInputChange}
            value={password}
            required
          />
          <Button type='submit'>Sign In</Button>
        </LoginForm>
        <StyledLink to='/forgotPassword'>Forgot your password?</StyledLink>
      </FormContainer>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(Login));

