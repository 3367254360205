import React, { Component } from 'react'
import { withRouter } from "../components/WithRouter";
import { Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, Icon } from '../components';
import axios from 'axios';
import { apiUrl } from '../helpers';
import Spinner from '../components/Spinner';

class Contentful extends Component {
    state = {
        data: [],
        filteredData: [],
        loading: false,
        status: undefined
    };

    componentDidMount() {
        this.getAllUrlsWithLabel();
    };

    getAllUrlsWithLabel = async () => {
        try {
            this.setState({ loading: true });
            const { data: { payload } } = await axios.get(apiUrl('/api/getContentfulUrl'));
            console.log(payload);
            this.setState({ loading: false, data: payload, filteredData: payload });
        } catch (error) {
            this.setState({ loading: false });
            console.log(error);
        }
    };

    getFilteredData = (status) => {
        const { data } = this.state;
        if (status !== undefined) {
            const filteredData = data.filter((dt) => dt.status === status);
            this.setState({ filteredData, status });
        } else {
            this.setState({ filteredData: data, status: undefined });
        }
    };
    render() {
        const { filteredData, status, loading } = this.state;
        return (
            <PageContainer>
                {loading && <Spinner />}
                <TableContainer maxHeight='92vh'>
                    <Table className="table  table-bordered">
                        <TableHead>
                            <TableRow>
                                <TableHeaderElement className='w-40' scope="col">Page ( Label )</TableHeaderElement>
                                <TableHeaderElement className='w-50' scope="col">URL</TableHeaderElement>
                                <TableHeaderElement className='w-10' scope="col">Status  {status === undefined && <Icon onClick={(e) => this.getFilteredData(true)} className="bi bi-square text-secondary"></Icon>}
                                    {status === true && <Icon onClick={(e) => this.getFilteredData(false)} className="bi bi-check-square text-success"></Icon>}
                                    {status === false && <Icon onClick={(e) => this.getFilteredData(undefined)} className="bi bi-x-square text-danger"></Icon>}</TableHeaderElement>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData && filteredData.map((dt, index) => (
                                <TableRow key={index}>
                                    <TableDataElement className='w-20'>{dt.page} <a href={dt.link} target="_blank" rel="noopener noreferrer">
                                        {' ( ' + dt.label + ' )'}
                                    </a> </TableDataElement>
                                    <TableDataElement className='w-20'><a href={dt.url} target="_blank" rel="noopener noreferrer">{dt.url}</a> </TableDataElement>
                                    <TableDataElement className='w-20'>{dt.status ? 'Active' : 'In-active'}</TableDataElement>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </PageContainer>
        )
    }
};

export default withRouter(Contentful);