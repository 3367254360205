import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
    DropdownIndicator: null
};

const DropDownStyle = {
    option: (provided, { isDisabled, isFocused }) => ({
        ...provided,
        backgroundColor: isDisabled ? null : isFocused ? '#4fb973' : null,
        color: isFocused ? 'white' : 'black'
    }),
    control: (provided) => ({
        ...provided,
        'boxShadow': 'inset 0 1px 1px 0 rgba(0, 0, 0, 0.16)',
        'borderColor': '#b9bcca',
        'fontSize': '12px',
        'color': '#161616',
        ':hover': {
            'borderColor': '#4fb973'
        },
        ':active': {
            'borderColor': '#4fb973'
        }
    })
};

const createOption = (label) => ({
    label,
    value: label
});

class MultiTextInput extends Component {
    state = {
        inputValue: '',
        value: this.props.data || []
    };
    handleChange = (value, actionMeta) => {
        value = value || [];
        this.setState({ value }, () => {
            this.props.onChange(value);
        });
    };
    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    };
    handleKeyDown = (event) => {
        const { inputValue, value } = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                this.setState(
                    {
                        inputValue: '',
                        value: [...value, createOption(inputValue)]
                    },
                    () => {
                        this.props.onChange(this.state.value);
                    }
                );
                event.preventDefault();
                break;
            default:
                console.log(`Error in switch`);
        }
    };
    render() {
        const { inputValue, value } = this.state;
        return (
            <CreatableSelect
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti
                styles={DropDownStyle}
                menuIsOpen={false}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                placeholder={this.props.placeholder}
                value={value}
            />
        );
    }
}

export default MultiTextInput;
