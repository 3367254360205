import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
const SpinnerContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
        
    .loader {
        animation: rotate 1s infinite;
        height: 50px;
        width: 50px;
    }

    .loader:before,
    .loader:after {
        border-radius: 50%;
        content: '';
        display: block;
        height: 20px;
        width: 20px;
    }

    .loader:before {
        animation: ball1 1s infinite;
        background-color: rgb(21, 126, 21);
        box-shadow: 30px 0 0 rgb(129, 187, 116);
        margin-bottom: 10px;
    }

    .loader:after {
        animation: ball2 1s infinite;
        background-color: rgb(129, 187, 116);
        box-shadow: 30px 0 0 rgb(21, 126, 21);
    }

    @keyframes rotate {
        0% {
            -webkit-transform: rotate(0deg) scale(0.8);
            -moz-transform: rotate(0deg) scale(0.8);
        }

        50% {
            -webkit-transform: rotate(360deg) scale(1.2);
            -moz-transform: rotate(360deg) scale(1.2);
        }

        100% {
            -webkit-transform: rotate(720deg) scale(0.8);
            -moz-transform: rotate(720deg) scale(0.8);
        }
    }

    @keyframes ball1 {
        0% {
            box-shadow: 30px 0 0 rgb(129, 187, 116);
        }

        50% {
            box-shadow: 0 0 0 rgb(129, 187, 116);
            margin-bottom: 0;
            -webkit-transform: translate(15px, 15px);
            -moz-transform: translate(15px, 15px);
        }

        100% {
            box-shadow: 30px 0 0 rgb(129, 187, 116);
            margin-bottom: 10px;
        }
    }

    @keyframes ball2 {
        0% {
            box-shadow: 30px 0 0 rgb(21, 126, 21)
        }

        50% {
            box-shadow: 0 0 0 rgb(21, 126, 21);
            margin-top: -20px;
            -webkit-transform: translate(15px, 15px);
            -moz-transform: translate(15px, 15px);
        }

        100% {
            box-shadow: 30px 0 0 rgb(21, 126, 21);
            margin-top: 0;
        }
    }
`;

class Spinner extends React.Component {
    render() {
        return (
            <Modal.Dialog>
                <Modal
                    show={true}
                    size="lg"
                    dialogClassName="spinner-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <SpinnerContainer>
                        <div className="loader"></div>
                    </SpinnerContainer>
                </Modal>
            </Modal.Dialog>
        );
    }
};
export default Spinner;