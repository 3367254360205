import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import Cookies from 'js-cookie';

const PrivateRoutes = (props) => {
    const { authenticated, user: { user } } = props;
    return (
        (authenticated && user && Cookies.get('adminToken')) ? <Outlet /> : <Navigate to="/login" />
    )
};

export default PrivateRoutes;
