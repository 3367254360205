import styled from 'styled-components';
import arrow from '../assets/images/glyph-arrow-right-green.svg';
import { Link } from 'react-router-dom';

export const PageContainer = styled.div`
    width:${(props) => (props.width ? props.width : '90%')};
    margin-top:1%;,
    display:flex;
    justify-content: space-around;
    align-self: flex-start;
    align-items: flex-start;
    .cursor-pointer{
      cursor: pointer;
    }
`;
export const SubHeader = styled.div`
    display:flex;
    margin-bottom:1%;
    @media only screen and (max-width:480px){
      display:block;
      width: 100%;
      margin: 1rem auto;
     }
`;
export const FilterContainer = styled.form`
    display:flex;
    width:70%;
    @media only screen and (max-width:480px){
          display:block;
          width: 100%;
         }
`;
export const Capitalize = styled.span`
    text-transform: capitalize;
`;
export const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 16px;
  color: Black;
  line-height: 22px;
  margin: 0;
  @media only screen and (max-width:480px){
    margin-bottom:10px;
   }
`;
export const StyledInput = styled.input`
    height: 37px;
    width:100%;
    border-radius: 4px;
    border: 0.5px solid #b9bcca;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);

`;
export const StyledTextarea = styled.textarea`
    width:95%;
    border-radius: 4px;
    border: 0.5px solid #b9bcca;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
`;
export const PaginationContainer = styled.div`
      display:flex;
      justify-content: end!important;
    .pagination{
        justify-content: end!important;
        font-size:12px;
        margin-bottom: 0;
          }
`;
export const PageCountContainer = styled.div`
    width:15%;
    position: relative;
    p{
      position: absolute;
      bottom: 0;
      right: 0;
      margin-bottom: 0.5rem;
      margin-right:0.5rem;
    }
`;
export const SubPaginationContainer = styled.div``;
export const InputContainer = styled.div`
    display:grid;
    font-size:16px;
    width:25%;

  @media only screen and (max-width:480px){
    display:block;
    width: 100%;
   }
  @media only screen and (max-width:768px) and (min-width:481px){
    width:50%;
    }
  @media only screen and (max-width:1024px) and (min-width:769px){
  width:50%;
  }
  @media only screen and (max-width:1441px) and (min-width:1025px){
    width:40%;
  }
`;
export const PageSizeContainer = styled.div`
    width:15%;
    bottom: 0;
    right: 0;
    @media only screen and (max-width:480px){
      width: 100%;
      margin-top: -20px;
     }
     @media only screen and (max-width:931px) and (min-width:481px){
     width:30%;
      }
`;
export const Icon = styled.i`
    cursor: pointer;
    padding: 0 5px;
    font-size: 16px;
`;
export const ArrowButton = styled.span`
  color: var(--key-color);
  font-weight: 600;
  cursor: pointer;
  &::after {
    content: '';
    display: inline-block;
    width: calc(1em - 1px);
    height: calc(1em - 3px);
    margin-left: 5px;
    background-image: url("${arrow}");
    background-size: cover;
    background-repeat: no-repeat;
    transition: margin-left 0.25s ease-out;
  }
  &:hover {
    &::after {
      margin-left: 10px; 
    }
  }
`;
export const SearchButton = styled.button`
        border-radius: 4px;
        padding: 6px 10px;
        margin-left:4%;
        min-width:57px;
        height: 33px;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-weight: 600;
        text-decoration: none;
        text-align: center;
        background:${(props) => (props.reset ? 'var(--light-text-color)' : 'var(--key-color)')};
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
        border: none;
        color: white;
        transition: box-shadow 0.2s;
        width: ${(props) => (props.width ? `${props.width}` : '100%')};
        cursor: pointer;
        &:disabled {
        cursor: not-allowed;
        background: #8ad8a5;
        border: 0.5px solid #eaeaea;
        color: #fbfbfb;
        }
        @media only screen and (max-width:480px){
          width: 50%;
          margin-left:0;
          margin-right: 3px;
             }
`;
export const ButtonContainer = styled.div`
        display:flex;
        ${(props) => (props.width && `width:${props.width}`)}
        @media only screen and (max-width:480px){
        margin-top:-22px;
             }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const FormContainer = styled(Container)`
  padding: 25px 35px;
  width: ${(props) => (props.width ? props.width : '325px')};
  background: #ffffff;
  border: 1px solid #d3d3d3;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  align-items: baseline;
  margin:${(props) => (props.margin ? props.margin : '3%')};

  // tablet
  @media (max-width:425px) {
    margin:${(props) => (props.margin ? props.margin : '5%')};
    width:100%;
    padding:10px;
    margin-left:${(props) => (props.margin ? props.margin : '-2rem')};
  }

  // desktop
  @media (min-width:1024px) {
    margin:${(props) => (props.margin ? props.margin : '5%')};
    padding:10px;
  }

  // huge
  @media (min-width:1440px) {
    margin:${(props) => (props.margin ? props.margin : '5%')};
  }

  // full hd display
  @media (min-width:2560px) {
    margin:${(props) => (props.margin ? props.margin : '5%')};
  }
`;

export const PasswordChangeForm = styled.form`
  width:100%;
`;
export const LoginHeader = styled(Container)`
  text-align: center;
  align-self: center;
  img {
    max-height: 50px;
  }
`;
export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  width: 100%;
  p {
    text-align: center;
  }
`;
export const StyledLink = styled(Link)`
  margin-bottom: 20px;
  font-size:14px;
  align-self: center;
`;
export const NoDataFound = styled.div`
  margin-bottom:20px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`;
export const CountTag = styled.strong`
  cursor:${(props) => (props.hoverOff ? 'not-allowed' : 'pointer')};
  color: ${(props) => (props.color ? props.color : 'black')};
  &:hover {
      text-decoration:${(props) => (props.hoverOff ? 'none' : 'underline')};
  }     
`;

export const RadioInput = styled.input`
  accent-color: green;
  height:20px;
  width:20px;
  margin-right: 0.5rem;
  margin-top:2px;
  cursor: pointer;
`;

export const FileUploadBtn = styled.label`
    border-radius: 4px;
    padding: 7px 10px;
    min-width: 50px;
    min-height: 35px;
    word-break: break-word;
    font-size: 12px;
    font-weight: 600;
    background: #fbfbfb;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14);
    border: 0.5px solid #eaeaea;
    color: #7f808f;
    text-align: center;
    -webkit-transition: box-shadow 0.2s;
    transition: box-shadow 0.2s;
    cursor: pointer;
`;

export const FileInput = styled.input`
    opacity: 0;
    pointer-events: none;
    width: 0%;
    height:0%;
`;