import { combineReducers } from 'redux'
import userReducer from './userReducer'
import practiceReducer from './practiceReducer'
import locationReducer from './locationReducer'
import showDetailsReducer from './showDetailsReducer'
import recordReducer from './recordReducer'
import masterReducer from './masterReducer'
import adminReducer from './adminReducer'

export default combineReducers({
    user: userReducer,
    practices: practiceReducer,
    records: recordReducer,
    locations: locationReducer,
    showDetails: showDetailsReducer,
    master: masterReducer,
    admin: adminReducer
});