import axios from 'axios'
import { toast } from 'react-toastify';
import actions from './actionTypes';
import { apiUrl } from '../helpers';


const userRequest = () => (dispatch) => {
    dispatch({
        type: actions.USER_REQUEST,
    });
};
const getAllUsersSuccess = (users) => (dispatch) => {
    dispatch({
        type: actions.GET_ALL_USERS_SUCCESS,
        users
    });
};
const getAllUsersError = () => async (dispatch) => {
    dispatch({
        type: actions.LOGIN_ERROR
    });
};
export const downloadUsers = (params) => async (dispatch) => {
    try {
        const { nameOrEmail, sortBy, order, practiceId, locationId, userId } = params;
        let apiEndPoint = `/api/export/users?`;

        if (sortBy && order) {
            apiEndPoint += 'sortBy=' + sortBy + '&order=' + order;
        };
        if (nameOrEmail) {
            apiEndPoint += '&nameOrEmail=' + encodeURIComponent(nameOrEmail);
        };
        if (practiceId) {
            apiEndPoint += '&practice=' + practiceId;
        };
        if (locationId) {
            apiEndPoint += '&location=' + locationId;
        };
        if (userId) {
            apiEndPoint += '&userId=' + userId;
        };
        const { data } = await axios.get(apiUrl(apiEndPoint), { responseType: 'blob' });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([data]));

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'users.xlsx'); // Set the file name

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up by removing the link and revoking the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.log("🚀 ~ getUsers ~ error:", error);
    }
};

export const getUsers = (params) => async (dispatch) => {
    try {
        dispatch(userRequest());
        const { pageSize, pageNumber, nameOrEmail, sortBy, order, practiceId, locationId, userId } = params;
        let apiEndPoint = `/api/allUsers`;
        if (pageSize) {
            apiEndPoint += '?pageSize=' + pageSize;
        };
        if (pageNumber) {
            apiEndPoint += '&pageNumber=' + pageNumber;
        };
        if (nameOrEmail) {
            apiEndPoint += '&nameOrEmail=' + encodeURIComponent(nameOrEmail);
        };
        if (practiceId) {
            apiEndPoint += '&practice=' + practiceId;
        };
        if (locationId) {
            apiEndPoint += '&location=' + locationId;
        };
        if (userId) {
            apiEndPoint += '&userId=' + userId;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getAllUsersSuccess(payload));
    } catch (error) {
        dispatch(getAllUsersError());
    }
};
export const updateUser = (user) => async (dispatch) => {
    try {
        const { data } = await axios.put(apiUrl(`/api/user/${user._id}`), user);
        toast.success('User data updated successfully.');
        return data;
    } catch (error) {
        if (error.response.status === 409) {
            toast.error('User email already exists.');
            return false;
        }
        toast.error('Error in user update.');
        return false;
    }
};

export const loginAsUser = (email) => async (dispatch) => {
    try {
        let apiEndPoint = `/api/user-login`;
        if (email) {
            apiEndPoint += '?email=' + encodeURIComponent(email);
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        return payload;
    } catch (error) {
        console.log(error);
        if (error.response.status === 422) {
            toast.error('The user has not completed the registration process.')
            return false;
        };
        return false;
    }
}
