import actionTypes from '../actions/actionTypes'

const initialState = {
    loading: false,
    locationPracticeID: '',
    userPracticeId: '',
    userLocationId: '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.LOCATION_PRACTICE_ID:
            return {
                ...state,
                loading: false,
                locationPracticeID: action.id
            }
        case actionTypes.USER_PRACTICE_ID:
            return {
                ...state,
                loading: false,
                userPracticeId: action.id
            }
        default:
            return state;
    }
}